import { connect } from 'react-redux';
import { signOut } from '../../store/actions/session';

import Header from './Header';

const mapStateToProps = ({ session: { isLoading } }) => ({
  isLoading,
});

const mapDispatchToProps = {
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
