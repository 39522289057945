import React from 'react';
import Box from '@material-ui/core/Box';

import Copyright from '../../components/Copyright';

import './index.scss';

const Footer = () => (
  <div className="footer-layout">
    <hr />
    <Box>
      <Copyright />
    </Box>
  </div>
);

export default Footer;
