import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CardMedia } from '@material-ui/core';
import { addBase64ImageHeader } from '../../../utils/images';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    maxWidth: 140,
    minWidth: 60,
    marginRight: 20,
  },
  objectSystemName: {
    flex: '0 1 50%',
  },
}));

const ObjectWithLogo = props => {
  const classes = useStyles();
  const { icon, objectSystemName } = props;

  return objectSystemName ? (
    <div className={classes.wrapper}>
      <div className={classes.icon}>
        {icon && (
          <CardMedia
            height="35"
            component="img"
            image={addBase64ImageHeader(icon)}
          />
        )}
      </div>
      <div className={classes.objectSystemName}>{objectSystemName}</div>
    </div>
  ) : null;
};

ObjectWithLogo.propTypes = {
  objectSystemName: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

ObjectWithLogo.defaultProps = {
  icon: '',
};

export default ObjectWithLogo;
