export const ADDITIONAL_TASK_STATES = {
  1001: {
    status: 'BROKEN',
    description: 'Серверна частина OLR Admin API вийшла з ладу та потребує втручання технічного спеціаліста',
  },
};

export const TRAINED_TASK_STATES = {
  1: {
    status: 'READY_FOR_START',
    description: 'Система готова до старту завдання',
  },
  200: {
    status: 'DATA_PREPARATION_STARTED',
    description: 'Почалася попередня обробка зображень',
  },
  210: {
    status: 'DATA_PREPARATION_STOPPED',
    description: 'Попередня обробка зображень була зупинена до свого завершення',
  },
  220: {
    status: 'TRAINING_STARTED',
    description: 'Безпосередньо тренування почалося',
  },
  230: {
    status: 'TRAINING_STOPPING',
    description: 'Запустився механізм зупинки тренувального процесу',
  },
  240: {
    status: 'TRAINING_STOPPED',
    description: 'Тренування зупинено',
  },
  250: {
    status: 'TRAINING_FINISHING',
    description: 'Почався процес фіналізації тренування',
  },
  260: {
    status: 'TRAINING_REMOVING',
    description: 'Процес видалення тимчасових тренувальних даних почався',
  },
  1000: {
    status: 'FINISHED',
    description: 'Тренування завершене',
  },
  ...ADDITIONAL_TASK_STATES,
};

export const RESTART_INSTANCE_TASK_STATES = {
  1: {
    status: 'READY_FOR_START',
    description: 'Система готова до старту завдання',
  },
  300: {
    status: 'RESTART_LAUNCH_PREPARING',
    description: 'Підготовка операції перезапуску',
  },
  310: {
    status: 'RESTART_REQUEST_SENDING',
    description: 'Надсилання команди stop до екземпляру розпізнавання (OLR API) та очікування відповіді від нього',
  },
  1000: {
    status: 'FINISHED',
    description: 'Завдання завершене',
  },
  ...ADDITIONAL_TASK_STATES,
};
