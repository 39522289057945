import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getObjects } from '../../store/actions/objects';

import VisualObjectsView from './VisualObjects';

const mapStateToProps = ({ objects: { isLoading, items, itemsPagination } }) => ({
  isLoading,
  visualObjects: items,
  visualObjectsPagination: itemsPagination,
});

const mapDispatchToProps = dispatch => ({
  getObjects: params => dispatch(getObjects(params)),
});

const VisualObjects = props => {
  const { page } = useParams();
  const location = useLocation();

  useEffect(() => {
    props.getObjects({ page, offset: location.state?.offset || 100 });
  }, [page, location.state]);

  return <VisualObjectsView {...props} />;
};

VisualObjects.propTypes = {
  getObjects: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(VisualObjects);
