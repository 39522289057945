import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getObjects, getObjectDefaultsSettings, startNewTraining } from '../../store/actions/objects';

import NewTrainingView from './NewTraining';

const mapStateToProps = ({ objects: { isLoading, items, itemsPagination, objectDefaultsSettings } }) => ({
  isLoading,
  visualObjects: items.map(item => ({
    ...item,
    augmentation: {
      rotation: objectDefaultsSettings.aug_rotation ?? true,
      fliph: objectDefaultsSettings.aug_fliph ?? true,
      flipv: objectDefaultsSettings.aug_flipv ?? true,
      noise: objectDefaultsSettings.aug_noise ?? true,
      blur: objectDefaultsSettings.aug_blur ?? true,
    },
  })),
  visualObjectsPagination: itemsPagination,
});

const mapDispatchToProps = dispatch => ({
  getObjects: params => dispatch(getObjects(params)),
  getObjectDefaultsSettings: () => dispatch(getObjectDefaultsSettings()),
  startNewTraining: payload => dispatch(startNewTraining(payload)),
});

const NewTraining = props => {
  const { page } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (!props.isLoading) {
      Promise.all([
        props.getObjectDefaultsSettings(),
        props.getObjects({ page, offset: location.state?.offset || 100 }),
      ]);
    }
  }, [page, location.state]);

  return <NewTrainingView {...props} />;
};

NewTraining.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  getObjects: PropTypes.func.isRequired,
  getObjectDefaultsSettings: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewTraining);
