import axios from 'axios';

class Auth {
  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_API_URL;
  }

  async signIn({ email, password }) {
    return (await axios.get(`${this.baseUrl}/instances`, {
      headers: {
        Authorization: `Basic ${window.btoa(`${email}:${password}`)}`,
      },
    }))?.data;
  }
}

export default new Auth();
