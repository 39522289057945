import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { NAVIGATION_MENU } from '../app/routes';

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: '#fff',
  },
})(Tabs);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    minHeight: 64,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
      borderRadius: '50%',
      opacity: 1,
    },
    '&$selected': {
      color: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#fff',
    },
  },
  selected: {},
}))(props => <Tab disableRipple={false} {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 40,
  },
  tabs: {
    flexGrow: 1,
  },
  account: {
    marginLeft: theme.spacing(2),
  },
  email: {
    marginLeft: theme.spacing(1),
  },
}));

const MenuAppBar = ({ signOut }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const email = localStorage.getItem('email');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tab, setTab] = React.useState(NAVIGATION_MENU.findIndex(item => pathname.includes(item.path.split('/')[1])));
  const open = Boolean(anchorEl);

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    handleClose();
    signOut();
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            OLR Admin
          </Typography>
          <StyledTabs
            className={classes.tabs}
            value={tab}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {NAVIGATION_MENU.map(({ path, name }) => (
              <StyledTab key={name} label={name} to={path} component={RouterLink} />
            ))}
          </StyledTabs>
          <div className={classes.account}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
              <Typography variant="subtitle2" className={classes.email}>
                {email || 'user'}
              </Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Light theme</MenuItem>
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

MenuAppBar.propTypes = {
  signOut: PropTypes.func.isRequired,
};

export default MenuAppBar;
