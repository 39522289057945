import { connect } from 'react-redux';
import { getClientRequestExampleImages } from '../../../store/actions/requests';

import ExampleImagesDialog from './ExampleImagesDialog';

const mapStateToProps = ({ requests: { exampleImages, isClientRequestExampleImagesLoading } }) => ({
  exampleImages,
  isClientRequestExampleImagesLoading,
});

const mapDispatchToProps = dispatch => ({
  getClientRequestExampleImages: requestId => dispatch(getClientRequestExampleImages(requestId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExampleImagesDialog);
