import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getInstanceLaunches } from '../../store/actions/instances';

import InstanceLaunchesView from './InstanceLaunches';

const mapStateToProps = ({
  instances: { items: olrInstances, launches, launchesPagination },
}) => ({
  olrInstances,
  launches,
  launchesPagination,
});

const mapDispatchToProps = dispatch => ({
  getInstanceLaunches: params => dispatch(getInstanceLaunches(params)),
});

const InstanceLaunches = props => {
  const { olrInstances } = props;
  const { instanceId, page } = useParams();
  const location = useLocation();
  const currentInstance = olrInstances.find(olrInstance => olrInstance.instance_id === Number(instanceId)) || {};

  useEffect(() => {
    props.getInstanceLaunches({ instanceId, page, offset: location.state?.offset || 100 });
  }, [instanceId, page, location.state]);

  return (
    <InstanceLaunchesView
      {...props}
      instanceName={currentInstance.instance_name}
      instanceServer={currentInstance.server}
    />
  );
};

InstanceLaunches.propTypes = {
  olrInstances: PropTypes.array.isRequired,
  getInstanceLaunches: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstanceLaunches);
