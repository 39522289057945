import { handleActions } from 'redux-actions';

import {
  SET_VERSIONS,
  SET_VERSIONS_LOADING,
  SET_VERSIONS_PAGINATION,
  SET_STOP_TRAINING_LOADING,
  SET_CONTINUE_TRAINING_LOADING,
  SET_FINISH_TRAINING_LOADING,
  SET_REMOVE_TRAINING_LOADING,
  SET_VERSION_OBJECTS,
  SET_VERSION_OBJECTS_LOADING,
  SET_VERSION_ACTIVE_LAUNCHES,
  SET_VERSION_ACTIVE_LAUNCHES_LOADING,
  SET_REMOVE_VERSION_LOADING,
} from '../actions/versions';

const INITIAL_STATE = {
  isLoading: false,
  isStopTrainingLoading: false,
  isContinueTrainingLoading: false,
  isFinishTrainingLoading: false,
  isRemoveTrainingLoading: false,
  isVersionObjectsLoading: false,
  isVersionActiveLaunchesLoading: false,
  isRemoveVersionLoading: false,
  items: [],
  itemsPagination: {
    page: 1,
    offset: 100,
    totalCount: 0,
  },
  versionObjects: [],
  versionActiveLaunches: [],
};

const ACTION_HANDLERS = {
  [SET_VERSIONS]: (state, { payload: items }) => ({
    ...state,
    items,
  }),
  [SET_VERSIONS_LOADING]: (state, { payload: { isLoading } }) => ({
    ...state,
    isLoading,
  }),
  [SET_VERSIONS_PAGINATION]: (state, { payload: itemsPagination }) => ({
    ...state,
    itemsPagination: {
      ...itemsPagination,
      totalCount: itemsPagination.total_count,
    },
  }),
  [SET_STOP_TRAINING_LOADING]: (state, { payload: { isStopTrainingLoading } }) => ({
    ...state,
    isStopTrainingLoading,
  }),
  [SET_CONTINUE_TRAINING_LOADING]: (state, { payload: { isContinueTrainingLoading } }) => ({
    ...state,
    isContinueTrainingLoading,
  }),
  [SET_FINISH_TRAINING_LOADING]: (state, { payload: { isFinishTrainingLoading } }) => ({
    ...state,
    isFinishTrainingLoading,
  }),
  [SET_REMOVE_TRAINING_LOADING]: (state, { payload: { isRemoveTrainingLoading } }) => ({
    ...state,
    isRemoveTrainingLoading,
  }),
  [SET_VERSION_OBJECTS]: (state, { payload: versionObjects }) => ({
    ...state,
    versionObjects,
  }),
  [SET_VERSION_OBJECTS_LOADING]: (state, { payload: { isVersionObjectsLoading } }) => ({
    ...state,
    isVersionObjectsLoading,
  }),
  [SET_VERSION_ACTIVE_LAUNCHES]: (state, { payload: versionActiveLaunches }) => ({
    ...state,
    versionActiveLaunches,
  }),
  [SET_VERSION_ACTIVE_LAUNCHES_LOADING]: (state, { payload: { isVersionActiveLaunchesLoading } }) => ({
    ...state,
    isVersionActiveLaunchesLoading,
  }),
  [SET_REMOVE_VERSION_LOADING]: (state, { payload: { isRemoveVersionLoading } }) => ({
    ...state,
    isRemoveVersionLoading,
  }),
};

export default handleActions(ACTION_HANDLERS, INITIAL_STATE);
