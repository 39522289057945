import ROUTES from './routes';
import NeuralNetworksPage from '../../components/NeuralNetworks';
import ClientRequestsPage from '../../components/ClientRequests';
import VisualObjectsPage from '../../components/VisualObjects';
import OlrInstancesPage from '../../components/OlrInstances';
import NewTrainingPage from '../../components/NewTraining';
import InstanceLaunchesPage from '../../components/InstanceLaunches';
import InstanceLaunchObjectsPage from '../../components/InstanceLaunchObjects';
import VersionObjectsPage from '../../components/VersionObjects';

const AUTH_ROUTES_LIST = [
  {
    ...ROUTES.neuralNetworks,
    component: NeuralNetworksPage,
    exact: true,
  },
  {
    ...ROUTES.versionObjects,
    component: VersionObjectsPage,
  },
  {
    ...ROUTES.clientRequests,
    component: ClientRequestsPage,
  },
  {
    ...ROUTES.visualObjects,
    component: VisualObjectsPage,
  },
  {
    ...ROUTES.olrInstances,
    component: OlrInstancesPage,
    exact: true,
  },
  {
    ...ROUTES.instanceLaunches,
    component: InstanceLaunchesPage,
  },
  {
    ...ROUTES.instanceLaunchObjects,
    component: InstanceLaunchObjectsPage,
  },
  {
    ...ROUTES.newTraining,
    component: NewTrainingPage,
  },
];

export default AUTH_ROUTES_LIST;
