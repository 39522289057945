import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Typography, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useCommonStyles } from '../NeuralNetworks/NeuralNetworks';
import { setItemIds } from '../../utils/itemIds';
import { capitalizeFirstLetter } from '../../utils/text';
import ROUTES from '../../layout/app/routes';

import CustomizedBreadcrumbs from '../commons/CustomizedBreadcrumbs';
import StickyHeadTable from '../commons/StickyHeadTable';
import StyledButton from '../commons/StyledButton';
import ObjectWithLogo from '../commons/ObjectWithLogo';

export const useStyles = makeStyles(() => ({
  title: {
    margin: '10px 5px',
  },
  subTitleWrapper: {
    margin: '10px 0 30px',
  },
  subTitle: {
    flex: '1 1 100%',
    margin: '10px 5px',
    fontSize: 14,
    '& span': {
      color: '#c3c3c3',
    },
  },
  styledButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  runButton: {
    minWidth: 110,
  },
}));

const InstanceLaunchObjects = props => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();
  const { launchObjects, instanceName, nnVersion, launchPeriod, restartInstance, isRestartInstanceLoading } = props;
  const { instanceId, launchId, launchMode } = useParams();
  const history = useHistory();
  const isEditMode = launchMode === 'edit';

  const [selectedLaunchObjects, setSelectedLaunchObjects] = useState([]);

  const handleCheckbox = (event, item) => {
    const selectedIndex = selectedLaunchObjects.findIndex(
      selectedLaunchObject => selectedLaunchObject.object_system_name === item.object_system_name,
    );
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedLaunchObjects, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedLaunchObjects.slice(1));
    } else if (selectedIndex === selectedLaunchObjects.length - 1) {
      newSelected = newSelected.concat(selectedLaunchObjects.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedLaunchObjects.slice(0, selectedIndex),
        selectedLaunchObjects.slice(selectedIndex + 1),
      );
    }

    setSelectedLaunchObjects(newSelected);
  };

  const isCheckboxSelected = item => selectedLaunchObjects
    .some(selectedLaunchObject => selectedLaunchObject.object_system_name === item.object_system_name);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      setSelectedLaunchObjects([...launchObjects]);
      return;
    }
    setSelectedLaunchObjects([]);
  };

  const goToInstanceLaunches = () => {
    history.push(`${ROUTES.instanceLaunches.path.replace(':instanceId', instanceId).replace(':page', '1')}`);
  };

  const handleRunNewLaunchObjects = () => {
    if (!selectedLaunchObjects.length) {
      return;
    }

    restartInstance({
      instance_id: instanceId,
      last_nn_version_id: nnVersion,
      launch_objects: selectedLaunchObjects,
      goToInstanceLaunches,
    });
  };

  useEffect(() => {
    handleSelectAllClick({ target: { checked: true } });
  }, []);

  const columnProps = [
    {
      field: 'object_system_name',
      label: 'Object',
      minWidth: 170,
      align: 'center',
      render: (value, item) => (
        <div style={{ display: 'flex' }}>
          <ObjectWithLogo
            icon={item.icon}
            objectSystemName={value}
          />
        </div>
      ),
    },
    {
      field: 'object_map',
      label: 'mAp',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'confidence_threshold',
      label: 'Threshold',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'enabled',
      label: 'Enabled',
      minWidth: 110,
      align: 'center',
      ...(isEditMode ? {
        renderHeadCell: () => (
          <FormControlLabel
            value="start"
            control={(
              <Checkbox
                className={classesCommon.checkbox}
                color="primary"
                onChange={handleSelectAllClick}
                checked={selectedLaunchObjects.length > 0 && selectedLaunchObjects.length === launchObjects.length}
                indeterminate={
                  selectedLaunchObjects.length > 0 && selectedLaunchObjects.length < launchObjects.length
                }
              />
            )}
            label="Enabled"
            labelPlacement="start"
          />
        ),
      } : {}),
      render: (value, item) => (isEditMode
        ? (
          <Checkbox
            className={classesCommon.checkbox}
            color="primary"
            checked={isCheckboxSelected(item)}
            onChange={event => handleCheckbox(event.target.checked, item)}
          />
        ) : capitalizeFirstLetter(String(value))),
    },
  ];

  return (
    <Container component="main" className={classesCommon.root} maxWidth={false}>
      <CustomizedBreadcrumbs
        className={classesCommon.breadcrumbs}
        breadcrumbRoutes={[
          { label: ROUTES.olrInstances.name, to: ROUTES.olrInstances.path },
          { label: instanceName, to: `${ROUTES.olrInstances.path}/${instanceId}/launches/1` },
          { label: `Launch id: ${launchId}`, to: '' },
        ]}
      />
      <Typography
        className={clsx(classesCommon.title, classes.title)}
        variant="h6"
        id="launchObjectsTableTitle"
        component="div"
      >
        Settings for current launch:
      </Typography>
      <div className={classes.subTitleWrapper}>
        <Typography className={classes.subTitle} variant="h6" id="nnVersion" component="div">
          NN Version: <span>{nnVersion}</span>
        </Typography>
        <Typography className={classes.subTitle} variant="h6" id="launchPeriod" component="div">
          Launch Period: <span>{launchPeriod}</span>
        </Typography>
      </div>
      <Box maxWidth={800} mb={7}>
        <StickyHeadTable
          columnProps={columnProps}
          items={setItemIds(launchObjects)}
          tableTitle="Launch Settings"
        />
        {isEditMode && (
          <div className={classes.styledButtonContainer}>
            <StyledButton
              className={classes.runButton}
              title="Run"
              paletteColor={green}
              isInProgress={isRestartInstanceLoading}
              onClick={handleRunNewLaunchObjects}
            />
          </div>
        )}
      </Box>
    </Container>
  );
};

InstanceLaunchObjects.propTypes = {
  launchObjects: PropTypes.array,
  instanceName: PropTypes.string,
  nnVersion: PropTypes.number,
  launchPeriod: PropTypes.string,
  isRestartInstanceLoading: PropTypes.bool,
  restartInstance: PropTypes.func,
};

InstanceLaunchObjects.defaultProps = {
  launchObjects: [],
  instanceName: '',
  nnVersion: 0,
  launchPeriod: '',
  isRestartInstanceLoading: false,
  restartInstance() { },
};

export default InstanceLaunchObjects;
