import { connect } from 'react-redux';
import { getVersionActiveLaunches } from '../../../store/actions/versions';

import VersionActiveLaunchesDialog from './VersionActiveLaunchesDialog';

const mapStateToProps = ({ versions: { versionActiveLaunches, isVersionActiveLaunchesLoading } }) => ({
  versionActiveLaunches,
  isVersionActiveLaunchesLoading,
});

const mapDispatchToProps = dispatch => ({
  getVersionActiveLaunches: versionId => dispatch(getVersionActiveLaunches(versionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionActiveLaunchesDialog);
