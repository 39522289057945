import { createAction } from 'redux-actions';
import Auth from '../../api/auth';
import ROUTES from '../../layout/app/routes';

export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
export const SET_ERROR = 'SET_ERROR';

export const setAuthenticated = createAction(SET_AUTHENTICATED, isAuthenticated => ({ isAuthenticated }));
export const setSessionLoading = createAction(SET_SESSION_LOADING, isLoading => ({ isLoading }));
export const setPageLoading = createAction(SET_PAGE_LOADING, isPageLoading => ({ isPageLoading }));
export const setError = createAction(SET_ERROR);

export const signIn = ({ email, password }, history) => (dispatch, getState) => {
  const { session: { isLoading } } = getState();
  const { from } = history.location.state || {};

  if (isLoading) {
    return;
  }

  dispatch(setSessionLoading(true));
  dispatch(setError(''));
  return Auth.signIn({ email, password })
    .then(() => {
      [
        { key: 'token', value: window.btoa(`${email}:${password}`) },
        { key: 'email', value: email },
      ].forEach(item => localStorage.setItem(item.key, item.value));
      dispatch(setAuthenticated(true));
      history.replace(from?.pathname || ROUTES.neuralNetworks.path);
    })
    .catch(error => {
      dispatch(setAuthenticated(false));
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
    })
    .finally(() => {
      dispatch(setSessionLoading(false));
    });
};

export const getUserToken = () => localStorage.getItem('token');

export const signOut = () => () => {
  ['token', 'email'].forEach(item => localStorage.removeItem(item));
  window.location.replace('/login');
};

export const handleCommonError = error => {
  if (error?.response?.status === 401) {
    signOut()();
  }
};
