export class Base {
  constructor(token) {
    this.baseUrl = process.env.REACT_APP_BASE_API_URL;
    this.token = token;
  }

  getConfig() {
    return {
      headers: {
        Authorization: `Basic ${this.token}`,
      },
    };
  }
}
