import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AUTH_ROUTES_LIST from './layout/app/authRoutesList';
import { setAuthenticated } from './store/actions/session';

import AuthRoute from './layout/app/authRoute';
import Layout from './layout/app';
import Login from './components/Login';
import NotFoundPage from './components/NotFoundPage';

import './index.scss';

function App() {
  const dispatch = useDispatch();
  const darkTheme = createTheme({
    palette: {
      type: 'dark',
    },
  });

  useEffect(() => {
    dispatch(setAuthenticated(!!localStorage.getItem('token')));
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="app-layout">
        <Router>
          <Switch>
            <Route exact path={['/', '/login']} component={Login} />
            {AUTH_ROUTES_LIST.map(({ path, exact }) => (
              <AuthRoute key={path} exact={exact} path={path} component={Layout} />
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
