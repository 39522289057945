import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Container, Box } from '@material-ui/core';
import { useCommonStyles } from '../NeuralNetworks/NeuralNetworks';
import { transformToDisabledEnabled } from '../../utils/text';
import { setItemIds } from '../../utils/itemIds';
import ROUTES from '../../layout/app/routes';

import CustomizedBreadcrumbs from '../commons/CustomizedBreadcrumbs';
import StickyHeadTable from '../commons/StickyHeadTable';
import ObjectWithLogo from '../commons/ObjectWithLogo';

const VersionObjects = props => {
  const classesCommon = useCommonStyles();
  const { versionObjects } = props;
  const { versionId } = useParams();

  const columnProps = [
    {
      field: 'object_system_name',
      label: 'Object',
      minWidth: 170,
      align: 'center',
      render: (value, item) => (
        <div style={{ display: 'flex' }}>
          <ObjectWithLogo
            icon={item.icon}
            objectSystemName={value}
          />
        </div>
      ),
    },
    {
      field: 'object_map',
      label: 'mAP',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'aug_rotation',
      label: 'Rotation',
      minWidth: 110,
      align: 'center',
      render: transformToDisabledEnabled,
    },
    {
      field: 'aug_fliph',
      label: 'FlipH',
      minWidth: 110,
      align: 'center',
      render: transformToDisabledEnabled,
    },
    {
      field: 'aug_flipv',
      label: 'FlipV',
      minWidth: 110,
      align: 'center',
      render: transformToDisabledEnabled,
    },
    {
      field: 'aug_noise',
      label: 'Noise',
      minWidth: 110,
      align: 'center',
      render: transformToDisabledEnabled,
    },
    {
      field: 'aug_blur',
      label: 'Blur',
      minWidth: 110,
      align: 'center',
      render: transformToDisabledEnabled,
    },
  ];

  return (
    <Container component="main" className={classesCommon.root} maxWidth={false}>
      <CustomizedBreadcrumbs
        className={classesCommon.breadcrumbs}
        breadcrumbRoutes={[
          { label: ROUTES.neuralNetworks.name, to: ROUTES.neuralNetworks.path.replace(':page', '1') },
          { label: `NN Version: ${versionId}`, to: '' },
        ]}
      />
      <Box maxWidth={1000} mt={5}>
        <StickyHeadTable
          columnProps={columnProps}
          items={setItemIds(versionObjects)}
          tableTitle={`Visual objects in v.${versionId}`}
        />
      </Box>
    </Container>
  );
};

VersionObjects.propTypes = {
  versionObjects: PropTypes.array,
};

VersionObjects.defaultProps = {
  versionObjects: [],
};

export default VersionObjects;
