import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import WallpaperTwoToneIcon from '@material-ui/icons/WallpaperTwoTone';
import { green, red, blueGrey } from '@material-ui/core/colors';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import ROUTES from '../../../layout/app/routes';

import StyledButton from '../../commons/StyledButton';

const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'lightBlue',
      },
    },
  },
})(TextField);

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiDialogActions-root': {
      margin: '15px 0',
      '& > button': {
        minWidth: '100px',
        '&:first-child': {
          marginRight: '30px',
        },
      },
    },
  },
  fieldWrapper: {
    display: 'flex',
    flex: '0 1 100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& label:first-child': {
      flex: '0 1 25%',
    },
  },
  fileField: {
    margin: '20px 8px 0 0',
  },
  input: {
    display: 'none',
  },
  titleDivider: {
    margin: '0 25px 10px',
  },
  actionsDivider: {
    margin: '30px 0 0',
  },
  iconFile: {
    maxWidth: 100,
  },
}));

const validationSchema = yup.object().shape({
  name: yup.string().required('Object Name is required'),
});

const CreateEditVisualObjectDialog = props => {
  const classes = useStyles();
  const history = useHistory();
  const { isOpen, onClose, visualObject, isLoading, createObject, editObject } = props;
  const isEdit = !!visualObject?.object_system_name;
  const [iconFile, setIconFile] = useState({});
  const [zipFile, setZipFile] = useState({});

  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    defaultValues: {
      icon: visualObject?.icon ?? null,
      images: visualObject?.images ?? null,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (isEdit) {
      setValue('name', visualObject?.object_system_name ?? '');
    }
  }, [isEdit]);

  const handleClose = () => {
    setValue('name', '');
    reset();
    setIconFile({});
    setZipFile({});
    onClose();
  };

  const onSubmit = data => {
    (isEdit ? editObject : createObject)({
      ...data,
      ...(!isEdit ? { request_id: visualObject?.request_id ?? null } : {}),
      ...(iconFile.base64Data ? { icon: iconFile.base64Data } : {}),
      ...(zipFile.base64Data ? { images: zipFile.base64Data } : {}),
    }).then(() => {
      history.push(ROUTES.visualObjects.path.replace(':page', '1'));
      handleClose();
    });
  };

  const handleChange = event => {
    const { files } = event.target;
    const allFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          base64: reader.result,
          base64Data: /base64,(.+)/.exec(String(reader.result))[1],
          file,
        };

        allFiles.push(fileInfo);

        if (allFiles.length === files.length) {
          if (file.type === 'application/zip') {
            setZipFile(allFiles[0]);
          } else {
            setIconFile(allFiles[0]);
          }
        }
      };
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={() => { }} maxWidth="sm" fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{isEdit ? 'Edit' : 'Create'} Visual Object</DialogTitle>
        <Divider className={classes.titleDivider} />
        <DialogContent>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className={classes.fieldWrapper}>
              <label htmlFor="name">Object Name: </label>
              <StyledTextField
                {...register('name')}
                id="name"
                type="name"
                variant="outlined"
                style={{ margin: 8 }}
                placeholder="os_huawei"
                fullWidth
                margin="normal"
                autoComplete="off"
                defaultValue={visualObject?.object_system_name ?? ''}
                onChange={event => setValue('name', event.target.value, true)}
                InputProps={{
                  ...(visualObject?.object_system_name ? { readOnly: true } : {}),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.name}
                helperText={errors.name?.message}
                required
                autoFocus
              />
            </div>
            {visualObject?.request_id && (
              <div className={classes.fieldWrapper}>
                <label htmlFor="request_id">Request ID: </label>
                <StyledTextField
                  {...register('request_id')}
                  id="request_id"
                  type="request_id"
                  variant="outlined"
                  defaultValue={visualObject?.request_id ?? ''}
                  style={{ margin: 8 }}
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                  disabled
                />
              </div>
            )}
            <div className={clsx(classes.fieldWrapper, classes.fileField)}>
              <label>Icon:</label>
              {iconFile.base64 ? (
                <div className={classes.iconFile}>
                  <CardMedia
                    height="50"
                    component="img"
                    image={iconFile.base64}
                  />
                </div>
              ) : (
                <WallpaperTwoToneIcon fontSize="large" />
              )}
              <input
                {...register('icon')}
                id="icon"
                className={classes.input}
                type="file"
                accept="image/*"
                onChange={handleChange}
              />
              <label htmlFor="icon">
                <StyledButton
                  title="Upload..."
                  paletteColor={blueGrey}
                  component="span"
                />
              </label>
            </div>
            <div className={clsx(classes.fieldWrapper, classes.fileField)}>
              <label>Images:</label>
              <p>{zipFile.name || 'Choose file'}</p>
              <input
                {...register('images')}
                id="images"
                className={classes.input}
                type="file"
                accept=".zip"
                onChange={handleChange}
              />
              <label htmlFor="images">
                <StyledButton
                  title="Upload..."
                  paletteColor={blueGrey}
                  component="span"
                />
              </label>
            </div>
            <Divider className={classes.actionsDivider} />
            <DialogActions>
              <StyledButton
                title="Cancel"
                paletteColor={red}
                onClick={handleClose}
                size="large"
                disabled={isLoading}
              />
              <StyledButton
                type="submit"
                title="Send"
                paletteColor={green}
                size="large"
                isInProgress={isLoading}
              />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

CreateEditVisualObjectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  createObject: PropTypes.func.isRequired,
  editObject: PropTypes.func.isRequired,
  visualObject: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
};

CreateEditVisualObjectDialog.defaultProps = {
  visualObject: null,
};

export default CreateEditVisualObjectDialog;
