import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getInstanceLaunchObjects, restartInstance } from '../../store/actions/instances';
import { getLaunchPeriod } from '../InstanceLaunches/InstanceLaunches';

import InstanceLaunchObjectsView from './InstanceLaunchObjects';

const mapStateToProps = ({
  instances: { isRestartInstanceLoading, items: olrInstances, launches, launchObjects },
}) => ({
  isRestartInstanceLoading,
  olrInstances,
  launches,
  launchObjects,
});

const mapDispatchToProps = dispatch => ({
  getInstanceLaunchObjects: payload => dispatch(getInstanceLaunchObjects(payload)),
  restartInstance: payload => dispatch(restartInstance(payload)),
});

const InstanceLaunchObjects = props => {
  const { olrInstances, launches } = props;
  const { instanceId, launchId } = useParams();
  const currentInstance = olrInstances.find(olrInstance => olrInstance.instance_id === Number(instanceId)) || {};
  const currentInstanceLaunch = launches.find(launch => launch.launch_id === Number(launchId)) || {};

  useEffect(() => {
    props.getInstanceLaunchObjects({ instanceId, launchId });
  }, [instanceId, launchId]);

  return (
    <InstanceLaunchObjectsView
      {...props}
      instanceName={currentInstance.instance_name}
      nnVersion={currentInstanceLaunch.nn_version_id}
      launchPeriod={getLaunchPeriod(currentInstanceLaunch)}
    />
  );
};

InstanceLaunchObjects.propTypes = {
  olrInstances: PropTypes.array.isRequired,
  launches: PropTypes.array.isRequired,
  getInstanceLaunchObjects: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstanceLaunchObjects);
