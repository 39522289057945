import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVersionObjects } from '../../store/actions/versions';

import VersionObjectsView from './VersionObjects';

const mapStateToProps = ({
  versions: { versionObjects },
}) => ({
  versionObjects,
});

const mapDispatchToProps = dispatch => ({
  getVersionObjects: versionId => dispatch(getVersionObjects(versionId)),
});

const VersionObjects = props => {
  const { versionId } = useParams();

  useEffect(() => {
    props.getVersionObjects(versionId);
  }, [versionId]);

  return (
    <VersionObjectsView {...props} />
  );
};

VersionObjects.propTypes = {
  getVersionObjects: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionObjects);
