import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { useCommonStyles } from '../NeuralNetworks/NeuralNetworks';
import ROUTES from '../../layout/app/routes';
import { getFormattedDate } from '../../utils/date';
import { setItemIds } from '../../utils/itemIds';

import StickyHeadTable from '../commons/StickyHeadTable';
import StyledButton from '../commons/StyledButton';
import ObjectWithLogo from '../commons/ObjectWithLogo';
import ButtonLink from '../commons/ButtonLink';
import CreateEditVisualObjectDialog from '../modals/CreateEditVisualObjectDialog';
import ExampleImagesDialog from '../modals/ExampleImagesDialog';

const ClientRequests = props => {
  const classes = useCommonStyles();
  const { clientRequests, clientRequestsPagination } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExampleImagesDialog, setIsOpenExampleImagesDialog] = useState(false);
  const [selectedVisualObject, setSelectedVisualObject] = useState(null);

  const handleOpenExampleImagesDialog = visualObject => {
    if (visualObject) {
      setSelectedVisualObject(visualObject);
    }

    setIsOpenExampleImagesDialog(true);
  };

  const handleCloseExampleImagesDialog = () => {
    setSelectedVisualObject(null);
    setIsOpenExampleImagesDialog(false);
  };

  const handleOpenVisualObjectDialog = visualObject => {
    if (visualObject) {
      setSelectedVisualObject(visualObject);
    }

    setIsOpen(true);
  };

  const handleCloseVisualObjectDialog = () => {
    setSelectedVisualObject(null);
    setIsOpen(false);
  };

  const columnProps = [
    {
      field: 'request_id',
      label: 'Request ID',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'user_name',
      label: 'User',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'client_object_name',
      label: 'Object Name',
      minWidth: 170,
      align: 'center',
    },
    {
      field: 'client_object_description',
      label: 'Object Description',
      minWidth: 170,
      align: 'center',
    },
    {
      field: 'request_time',
      label: 'Request Created',
      minWidth: 110,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'active_since',
      label: 'Object Active Since',
      minWidth: 110,
      align: 'center',
      render: (value, item) => (item.object_system_name ? value ? getFormattedDate(value) : 'Logo created' : 'Pending'),
    },
    {
      field: 'exampleImages',
      label: 'Example Images',
      minWidth: 110,
      align: 'center',
      render: (_, item) => (
        <ButtonLink
          title="show images"
          onClick={() => handleOpenExampleImagesDialog(item)}
        />
      ),
    },
    {
      field: 'object_system_name',
      label: 'Object',
      minWidth: 170,
      align: 'center',
      render: (value, item) => (value ? (
        <div style={{ display: 'flex' }}>
          <ObjectWithLogo
            icon={item.object_icon}
            objectSystemName={value}
          />
        </div>
      ) : (
        <StyledButton
          title="Create Object..."
          paletteColor={green}
          onClick={() => handleOpenVisualObjectDialog(item)}
        />
      )),
    },
    {
      field: 'im_total',
      label: 'Total Object Images',
      minWidth: 170,
      align: 'center',
      render: value => String(value),
    },
  ];

  return (
    <Container component="main" className={classes.root} maxWidth={false}>
      <StickyHeadTable
        columnProps={columnProps}
        items={setItemIds(clientRequests)}
        itemsPagination={{ ...clientRequestsPagination, pathname: ROUTES.clientRequests.path }}
      />
      <CreateEditVisualObjectDialog
        isOpen={isOpen}
        visualObject={selectedVisualObject}
        onClose={handleCloseVisualObjectDialog}
      />
      <ExampleImagesDialog
        isOpen={isOpenExampleImagesDialog}
        visualObject={selectedVisualObject}
        onClose={handleCloseExampleImagesDialog}
      />
    </Container>
  );
};

ClientRequests.propTypes = {
  clientRequests: PropTypes.array,
  clientRequestsPagination: PropTypes.object,
};

ClientRequests.defaultProps = {
  clientRequests: [],
  clientRequestsPagination: {},
};

export default ClientRequests;
