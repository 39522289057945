import React from 'react';
import Typography from '@material-ui/core/Typography';

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    &copy;
    {' '}
    {new Date().getFullYear()}
    {' '}
    - OLR Admin
  </Typography>
);

export default Copyright;
