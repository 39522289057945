import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInstancesData, startInstance, restartInstance, stopInstance } from '../../store/actions/instances';

import OlrInstancesView from './OlrInstances';

const mapStateToProps = ({
  instances: { items: olrInstances, isStartInstanceLoading, isRestartInstanceLoading, isStopInstanceLoading },
  tasksState: { items: tasksState },
}) => ({
  olrInstances,
  isStartInstanceLoading,
  isRestartInstanceLoading,
  isStopInstanceLoading,
  tasksState,
});

const mapDispatchToProps = dispatch => ({
  getInstancesData: () => dispatch(getInstancesData()),
  startInstance: payload => dispatch(startInstance(payload)),
  restartInstance: payload => dispatch(restartInstance(payload)),
  stopInstance: payload => dispatch(stopInstance(payload)),
});

const OlrInstances = props => {
  useEffect(() => {
    props.getInstancesData();
  }, []);

  return <OlrInstancesView {...props} />;
};

OlrInstances.propTypes = {
  getInstancesData: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OlrInstances);
