import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Container, Link, Typography, Box } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import { getVersionObjectsPath, useCommonStyles } from '../NeuralNetworks/NeuralNetworks';
import { getFormattedDate } from '../../utils/date';
import { setItemIds } from '../../utils/itemIds';
import ROUTES from '../../layout/app/routes';

import CustomizedBreadcrumbs from '../commons/CustomizedBreadcrumbs';
import StickyHeadTable from '../commons/StickyHeadTable';
import StyledButton from '../commons/StyledButton';

export const useStyles = makeStyles(() => ({
  title: {
    margin: '10px 5px',
  },
  subTitleWrapper: {
    margin: '10px 0 30px',
  },
  subTitle: {
    flex: '1 1 100%',
    margin: '10px 5px',
    fontSize: 14,
    '& span': {
      color: '#c3c3c3',
    },
  },
}));

export const getLaunchObjectsPath = (instanceId, item, mode = 'view') => (
  `${ROUTES.olrInstances.path}/${instanceId}/launch/${item.launch_id}/objects/${mode}`
);

export const getLaunchPeriod = ({ start_time: startTime, stop_time: stopTime }) => (
  startTime ? `${getFormattedDate(startTime)} - ${stopTime ? getFormattedDate(stopTime) : 'Now'}` : 'None'
);

const InstanceLaunches = props => {
  const classes = useStyles();
  const classesCommon = useCommonStyles();
  const { launches, launchesPagination, instanceName, instanceServer } = props;
  const { instanceId } = useParams();
  const history = useHistory();

  const setBodyCellBgColor = (value, item) => item.start_time && !item.stop_time && '#8a4a4a';

  const columnProps = [
    {
      field: 'start_time',
      label: 'Launch Period',
      minWidth: 170,
      align: 'center',
      bodyCellBgColor: setBodyCellBgColor,
      render: (value, item) => (
        <Link
          className={classesCommon.link}
          component={RouterLink}
          to={getLaunchObjectsPath(instanceId, item)}
        >
          {getLaunchPeriod(item)}
        </Link>
      ),
    },
    {
      field: 'nn_version_id',
      label: 'NN Version',
      minWidth: 110,
      align: 'center',
      bodyCellBgColor: setBodyCellBgColor,
      render: value => (
        <Link
          className={classesCommon.link}
          component={RouterLink}
          to={getVersionObjectsPath(value)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'train_finished',
      label: 'Trained',
      minWidth: 170,
      align: 'center',
      bodyCellBgColor: setBodyCellBgColor,
      render: value => getFormattedDate(value),
    },
    {
      field: 'action',
      label: 'Action',
      minWidth: 110,
      align: 'center',
      bodyCellBgColor: setBodyCellBgColor,
      render: (value, item) => (
        <div className={classesCommon.actionButtons}>
          <StyledButton
            title="Select"
            paletteColor={green}
            onClick={() => {
              history.push(getLaunchObjectsPath(instanceId, item, 'edit'));
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <Container component="main" className={classesCommon.root} maxWidth={false}>
      <CustomizedBreadcrumbs
        className={classesCommon.breadcrumbs}
        breadcrumbRoutes={[
          { label: ROUTES.olrInstances.name, to: ROUTES.olrInstances.path },
          { label: instanceName, to: '' },
        ]}
      />
      <Typography
        className={clsx(classesCommon.title, classes.title)}
        variant="h6"
        id="launchesTableTitle"
        component="div"
      >
        Select NN version for current OLR instance:
      </Typography>
      <div className={classes.subTitleWrapper}>
        <Typography className={classes.subTitle} variant="h6" id="instanceServer" component="div">
          Instance Server: <span>{instanceServer}</span>
        </Typography>
      </div>
      <Box maxWidth={1000}>
        <StickyHeadTable
          columnProps={columnProps}
          items={setItemIds(launches)}
          itemsPagination={{ ...launchesPagination, pathname: ROUTES.instanceLaunches.path }}
        />
      </Box>
    </Container>
  );
};

InstanceLaunches.propTypes = {
  launches: PropTypes.array,
  launchesPagination: PropTypes.object,
  instanceName: PropTypes.string,
  instanceServer: PropTypes.string,
};

InstanceLaunches.defaultProps = {
  launches: [],
  launchesPagination: {},
  instanceName: '',
  instanceServer: '',
};

export default InstanceLaunches;
