import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      localStorage.getItem('token')
        ? <Component {...props} />
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )}
  />
);

AuthRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
};

AuthRoute.defaultProps = {
  location: {},
};

export default AuthRoute;
