import { handleActions } from 'redux-actions';

import {
  SET_TASKS_STATE,
  SET_TASKS_STATE_LOADING,
} from '../actions/tasksState';

const INITIAL_STATE = {
  isLoading: false,
  items: [],
};

const ACTION_HANDLERS = {
  [SET_TASKS_STATE]: (state, { payload: items }) => ({
    ...state,
    items,
  }),
  [SET_TASKS_STATE_LOADING]: (state, { payload: { isLoading } }) => ({
    ...state,
    isLoading,
  }),
};

export default handleActions(ACTION_HANDLERS, INITIAL_STATE);
