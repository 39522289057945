import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { teal } from '@material-ui/core/colors';
import { Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  circle: {
    color: '#fff',
  },
}));

const StyledButton = props => {
  const classes = useStyles();
  const { title, paletteColor, isInProgress, ...rest } = props;
  const ColorButton = withStyles(theme => ({
    root: {
      textTransform: 'none',
      color: theme.palette.getContrastText(paletteColor[600]),
      backgroundColor: paletteColor[600],
      '&:hover': {
        backgroundColor: paletteColor[800],
      },
    },
  }))(Button);

  return (
    <ColorButton {...rest} variant="contained" color="primary">
      {isInProgress ? <CircularProgress className={classes.circle} size={24} /> : title}
    </ColorButton>
  );
};

StyledButton.propTypes = {
  title: PropTypes.string.isRequired,
  paletteColor: PropTypes.object,
  isInProgress: PropTypes.bool,
};

StyledButton.defaultProps = {
  paletteColor: teal,
  isInProgress: false,
};

export default StyledButton;
