import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Container, Typography, Tooltip, Link } from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { getFormattedDate } from '../../utils/date';
import { setItemIds } from '../../utils/itemIds';
import { TRAINED_TASK_STATES } from '../../utils/tasksStateConstants';
import ROUTES from '../../layout/app/routes';

import StickyHeadTable from '../commons/StickyHeadTable';
import StyledButton from '../commons/StyledButton';
import ButtonLink from '../commons/ButtonLink';
import VersionActiveLaunchesDialog from '../modals/VersionActiveLaunchesDialog';

export const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const useCommonStyles = makeStyles(() => ({
  root: {
    margin: '70px 0',
  },
  title: {
    flex: '1 1 100%',
    margin: '50px 5px 0',
    '&:first-child': {
      marginTop: 20,
    },
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    '& button': {
      minWidth: 92,
      marginLeft: 20,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  usedInstances: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '135px',
    '& button': {
      minWidth: 'auto',
      padding: '0 6px',
    },
  },
  link: {
    color: '#90caf9',
    textDecoration: 'underline',
  },
  breadcrumbs: {
    margin: '15px 0 20px',
  },
  checkbox: {
    '&.MuiCheckbox-root': {
      color: '#90caf9',
    },
    '&.MuiCheckbox-colorPrimary.Mui-checked': {
      color: '#90caf9',
    },
  },
}));

export const getVersionObjectsPath = versionId => `${ROUTES.neuralNetworks.path
  .replace('/:page', '')}/${versionId}/objects`;

const NeuralNetworks = props => {
  const classes = useCommonStyles();
  const {
    versions,
    versionsPagination,
    tasksState,
    stopTraining,
    continueTraining,
    finishTraining,
    removeTraining,
    removeVersion,
    isStopTrainingLoading,
    isContinueTrainingLoading,
    isFinishTrainingLoading,
    isRemoveTrainingLoading,
    isRemoveVersionLoading,
  } = props;
  const allVersions = setItemIds(versions);
  const trainedTasksState = tasksState.find(task => task.type === 2) || {};

  const [isOpenVersionActiveLaunches, setIsOpenVersionActiveLaunches] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [removingVersionId, setRemovingVersionId] = useState(null);

  const handleRemoveVersion = versionId => {
    setRemovingVersionId(versionId);
    removeVersion(versionId);
  };

  const handleOpenVersionActiveLaunchesDialog = version => {
    if (version) {
      setSelectedVersion(version);
    }

    setIsOpenVersionActiveLaunches(true);
  };

  const handleCloseVersionActiveLaunchesDialog = () => {
    setSelectedVersion(null);
    setIsOpenVersionActiveLaunches(false);
  };

  const trainedVersionColumnProps = [
    {
      field: 'version_id',
      label: 'Version',
      minWidth: 110,
      align: 'center',
      render: value => (
        <Link
          className={classes.link}
          component={RouterLink}
          to={getVersionObjectsPath(value)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'train_started',
      label: 'Started',
      minWidth: 150,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'train_finished',
      label: 'Finished',
      minWidth: 150,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'status',
      label: 'Status',
      minWidth: 110,
      align: 'center',
      render: () => (
        <HtmlTooltip title={TRAINED_TASK_STATES[trainedTasksState.state]?.description ?? ''} placement="top">
          <div>{TRAINED_TASK_STATES[trainedTasksState.state]?.status}</div>
        </HtmlTooltip>
      ),
    },
    {
      field: 'actions',
      label: 'Actions',
      minWidth: 110,
      align: 'center',
      render: () => (
        <div className={classes.actionButtons}>
          {(!trainedTasksState.state || [200, 220].includes(trainedTasksState.state)) && (
            <StyledButton
              title="Stop"
              paletteColor={red}
              onClick={stopTraining}
              isInProgress={isStopTrainingLoading}
              disabled={isContinueTrainingLoading || isFinishTrainingLoading || isRemoveTrainingLoading}
            />
          )}
          {[1, 210, 240, 1000].includes(trainedTasksState.state) && (
            <StyledButton
              title="Continue"
              paletteColor={red}
              onClick={continueTraining}
              isInProgress={isContinueTrainingLoading}
              disabled={isStopTrainingLoading || isFinishTrainingLoading || isRemoveTrainingLoading}
            />
          )}
          {[240].includes(trainedTasksState.state) && (
            <StyledButton
              title="Finish"
              paletteColor={green}
              onClick={() => finishTraining({ iteration: trainedTasksState.finish_iteration })}
              isInProgress={isFinishTrainingLoading}
              disabled={isStopTrainingLoading || isContinueTrainingLoading || isRemoveTrainingLoading}
            />
          )}
          {[240, 1000].includes(trainedTasksState.state) && (
            <StyledButton
              title="Remove"
              paletteColor={green}
              onClick={removeTraining}
              isInProgress={isRemoveTrainingLoading}
              disabled={isStopTrainingLoading || isContinueTrainingLoading || isFinishTrainingLoading}
            />
          )}
        </div>
      ),
    },
  ];

  const allVersionsColumnProps = [
    {
      field: 'version_id',
      label: 'Version',
      minWidth: 110,
      align: 'center',
      render: value => (
        <Link
          className={classes.link}
          component={RouterLink}
          to={getVersionObjectsPath(value)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'train_started',
      label: 'Train Started',
      minWidth: 170,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'train_finished',
      label: 'Train Finished',
      minWidth: 170,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'average_map',
      label: 'Average mAP',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'actions',
      label: 'Actions',
      minWidth: 110,
      align: 'center',
      render: (_, item) => (
        <div className={classes.actionButtons}>
          {item.number_of_launches ? (
            <div className={classes.usedInstances}>
              Used on <ButtonLink
                title={String(item.number_of_launches)}
                onClick={() => handleOpenVersionActiveLaunchesDialog(item)}
              /> instance{item.number_of_launches > 1 ? 's' : ''}
            </div>
          ) : (
            <div className={classes.usedInstances}>Currently not used</div>
          )}
          <StyledButton
            title="Delete"
            paletteColor={red}
            onClick={() => handleRemoveVersion(item.version_id)}
            isInProgress={isRemoveVersionLoading && item.version_id === removingVersionId}
            disabled={item.number_of_launches > 0}
          />
        </div>
      ),
    },
  ];

  return (
    <Container component="main" className={classes.root} maxWidth={false}>
      <Typography className={classes.title} variant="h6" id="trainedTableTitle" component="div">
        Last Trained:
      </Typography>
      <Box maxWidth={1000}>
        <StickyHeadTable
          columnProps={trainedVersionColumnProps}
          items={allVersions.slice(0, 1)}
          hasPagination={false}
        />
      </Box>
      <Typography className={classes.title} variant="h6" id="versionsTableTitle" component="div">
        All Versions:
      </Typography>
      <Box maxWidth={1000}>
        <StickyHeadTable
          columnProps={allVersionsColumnProps}
          items={allVersions}
          itemsPagination={{ ...versionsPagination, pathname: ROUTES.neuralNetworks.path }}
        />
      </Box>
      <VersionActiveLaunchesDialog
        isOpen={isOpenVersionActiveLaunches}
        version={selectedVersion}
        onClose={handleCloseVersionActiveLaunchesDialog}
      />
    </Container>
  );
};

NeuralNetworks.propTypes = {
  isStopTrainingLoading: PropTypes.bool,
  isContinueTrainingLoading: PropTypes.bool,
  isFinishTrainingLoading: PropTypes.bool,
  isRemoveTrainingLoading: PropTypes.bool,
  isRemoveVersionLoading: PropTypes.bool,
  versions: PropTypes.array,
  versionsPagination: PropTypes.object,
  tasksState: PropTypes.array,
  stopTraining: PropTypes.func,
  continueTraining: PropTypes.func,
  finishTraining: PropTypes.func,
  removeTraining: PropTypes.func,
  removeVersion: PropTypes.func,
};

NeuralNetworks.defaultProps = {
  isStopTrainingLoading: false,
  isContinueTrainingLoading: false,
  isFinishTrainingLoading: false,
  isRemoveTrainingLoading: false,
  isRemoveVersionLoading: false,
  versions: [],
  versionsPagination: {},
  tasksState: [],
  stopTraining() { },
  continueTraining() { },
  finishTraining() { },
  removeTraining() { },
  removeVersion() { },
};

export default NeuralNetworks;
