import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from '@material-ui/core';
import { teal } from '@material-ui/core/colors';
import { useCommonStyles } from '../NeuralNetworks/NeuralNetworks';
import ROUTES from '../../layout/app/routes';
import { getFormattedDate } from '../../utils/date';
import { setItemIds } from '../../utils/itemIds';
import { capitalizeFirstLetter } from '../../utils/text';

import StickyHeadTable from '../commons/StickyHeadTable';
import StyledButton from '../commons/StyledButton';
import ObjectWithLogo from '../commons/ObjectWithLogo';
import ButtonLink from '../commons/ButtonLink';
import CreateEditVisualObjectDialog from '../modals/CreateEditVisualObjectDialog';

const VisualObjects = props => {
  const classes = useCommonStyles();
  const { visualObjects, visualObjectsPagination } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedVisualObject, setSelectedVisualObject] = useState(null);

  const handleOpenVisualObjectDialog = visualObject => {
    if (visualObject) {
      setSelectedVisualObject(visualObject);
    }

    setIsOpen(true);
  };

  const handleCloseVisualObjectDialog = () => {
    setSelectedVisualObject(null);
    setIsOpen(false);
  };

  const columnProps = [
    {
      field: 'object_system_name',
      label: 'Object',
      minWidth: 170,
      align: 'center',
      render: (value, item) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ObjectWithLogo
            icon={item.icon}
            objectSystemName={value}
          />
          <ButtonLink title="Edit" onClick={() => handleOpenVisualObjectDialog(item)} />
        </div>
      ),
    },
    {
      field: 'request_id',
      label: 'Request ID',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'creation_time',
      label: 'Created',
      minWidth: 170,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'last_update_time',
      label: 'Last Updated',
      minWidth: 170,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'im_train_true',
      label: 'Train True',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'im_train_false',
      label: 'Train False',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'im_valid_true',
      label: 'Valid True',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'im_valid_false',
      label: 'Valid False',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'last_map',
      label: 'Last mAP',
      minWidth: 170,
      align: 'center',
    },
    {
      field: 'enabled',
      label: 'Enabled',
      minWidth: 170,
      align: 'center',
      render: value => capitalizeFirstLetter(String(value)),
    },
  ];

  return (
    <Container component="main" className={classes.root} maxWidth={false}>
      <StickyHeadTable
        columnProps={columnProps}
        items={setItemIds(visualObjects)}
        itemsPagination={{ ...visualObjectsPagination, pathname: ROUTES.visualObjects.path }}
      />
      <StyledButton
        title="Create..."
        paletteColor={teal}
        style={{ marginTop: 10 }}
        onClick={() => handleOpenVisualObjectDialog()}
      />
      <CreateEditVisualObjectDialog
        isOpen={isOpen}
        visualObject={selectedVisualObject}
        onClose={handleCloseVisualObjectDialog}
      />
    </Container>
  );
};

VisualObjects.propTypes = {
  visualObjects: PropTypes.array,
  visualObjectsPagination: PropTypes.object,
};

VisualObjects.defaultProps = {
  visualObjects: [],
  visualObjectsPagination: {},
};

export default VisualObjects;
