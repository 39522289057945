import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import { Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import Loading from '../../commons/Loading';
import StyledButton from '../../commons/StyledButton';
import StickyHeadTable from '../../commons/StickyHeadTable';

import { getFormattedDate } from '../../../utils/date';
import { setItemIds } from '../../../utils/itemIds';

const useStyles = makeStyles(() => ({
  dialogWrapper: {
    '& .MuiDialog-paper': {
      backgroundColor: '#303030',
    },
  },
  tableWrapper: {
    display: 'flex',
    flex: '0 1 100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '570px',
    minHeight: '205px',
  },
  subTitle: {
    flex: '1 1 100%',
    margin: '0 5px',
    fontSize: 14,
    '& span': {
      color: '#c3c3c3',
    },
  },
  titleDivider: {
    margin: '0 25px 10px',
  },
  actionsDivider: {
    margin: '10px 0',
  },
}));

const VersionActiveLaunchesDialog = props => {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    version,
    versionActiveLaunches,
    isVersionActiveLaunchesLoading,
    getVersionActiveLaunches,
  } = props;

  const versionActiveLaunchesColumnProps = [
    {
      field: 'launch_id',
      label: 'Launch ID',
      minWidth: 170,
      align: 'center',
    },
    {
      field: 'instance_id',
      label: 'Instance ID',
      minWidth: 170,
      align: 'center',
    },
    {
      field: 'start_time',
      label: 'Start Time',
      minWidth: 230,
      align: 'center',
      render: value => getFormattedDate(value),
    },
  ];

  useEffect(() => {
    if (version) {
      getVersionActiveLaunches(version.version_id);
    }
  }, [version]);

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={() => { }} maxWidth="md" className={classes.dialogWrapper}>
        <DialogTitle>Neural Network Active Launches</DialogTitle>
        <Divider className={classes.titleDivider} />
        <DialogContent>
          {!!version && (
            <Typography className={classes.subTitle} variant="h6" id="nnVersion" component="div">
              NN Version: <span>{version.version_id}</span>
            </Typography>
          )}
          <div className={classes.tableWrapper}>
            {isVersionActiveLaunchesLoading ? (
              <Loading />
            ) : (
              <StickyHeadTable
                columnProps={versionActiveLaunchesColumnProps}
                items={setItemIds(versionActiveLaunches)}
              />
            )}
          </div>
          <Divider className={classes.actionsDivider} />
          <DialogActions>
            <StyledButton
              title="Close"
              paletteColor={green}
              onClick={handleClose}
              size="large"
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

VersionActiveLaunchesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isVersionActiveLaunchesLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  getVersionActiveLaunches: PropTypes.func.isRequired,
  version: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
  versionActiveLaunches: PropTypes.array.isRequired,
};

VersionActiveLaunchesDialog.defaultProps = {
  version: null,
};

export default VersionActiveLaunchesDialog;
