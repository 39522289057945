import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

const CustomizedBreadcrumbs = props => {
  const { breadcrumbRoutes, ...rest } = props;

  return (
    <Breadcrumbs {...rest} aria-label="breadcrumb">
      {breadcrumbRoutes.map(({ label, to }, index, array) => (array.length - 1 === index
        ? (
          <Typography key={label} color="textPrimary">{label}</Typography>
        )
        : (
          <Link
            key={label}
            color="inherit"
            component={RouterLink}
            to={to}
          >
            {label}
          </Link>
        )))}
    </Breadcrumbs>
  );
};

CustomizedBreadcrumbs.propTypes = {
  breadcrumbRoutes: PropTypes.array.isRequired,
};

export default CustomizedBreadcrumbs;
