import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, darken, lighten } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import StyledButton from '../../commons/StyledButton';

const getColor = theme => (theme.palette.type === 'light' ? darken : lighten);
const getBackgroundColor = theme => (theme.palette.type === 'light' ? lighten : darken);

const styles = theme => ({
  root: {
    backgroundColor: getBackgroundColor(theme)(theme.palette.error.main, 0.9),
    margin: 0,
    padding: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: getColor(theme)(theme.palette.error.main, 0.6),
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
      width: 28,
      height: 28,
      marginRight: 20,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '& .MuiSvgIcon-root': {
      width: 18,
      height: 18,
    },
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <div className={classes.title}>
        <ErrorOutlineIcon />
        <Typography variant="h6">{children}</Typography>
      </div>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: '0 20px',
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: '10px 15px 10px 20px',
    padding: theme.spacing(1),
    '& .MuiButton-root': {
      color: getColor(theme)(theme.palette.error.main, 0.6),
      backgroundColor: getBackgroundColor(theme)(theme.palette.error.main, 0.9),
      '&:hover': {
        color: '#999',
        backgroundColor: getBackgroundColor(theme)(theme.palette.error.main, 0.9),
      },
    },
  },
}))(MuiDialogActions);

const CommonErrorDialog = props => {
  const { error, resetError } = props;

  const handleClose = () => {
    resetError();
  };

  return (
    <div>
      <Dialog open={!!error} onClose={handleClose} maxWidth="sm" fullWidth aria-labelledby="error-dialog-title">
        <DialogTitle id="error-dialog-title" onClose={handleClose}>Error</DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <StyledButton
            title="OK"
            paletteColor={blueGrey}
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

CommonErrorDialog.propTypes = {
  error: PropTypes.string,
  resetError: PropTypes.func,
};

CommonErrorDialog.defaultProps = {
  error: '',
  resetError() { },
};

export default CommonErrorDialog;
