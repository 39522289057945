import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Avatar, Button, TextField, Typography, Container, Paper, CircularProgress } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Footer from '../../layout/footer';

import './index.scss';

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Email is invalid'),
  password: yup.string().required('Password is required').min(3, 'Password must be at least 3 characters'),
});

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  circle: {
    color: '#fff',
  },
}));

const Login = props => {
  const classes = useStyles();
  const { isLoading, signIn, error, resetError } = props;

  const { handleSubmit, register, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Paper elevation={0}>
          <div className="login-wrapper">
            <Avatar className="avatar">
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className="login-form" noValidate onSubmit={handleSubmit(signIn)}>
              <TextField
                {...register('email')}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                autoFocus
              />
              <TextField
                {...register('password')}
                id="password"
                type="password"
                label="Password"
                name="password"
                error={!!errors.password}
                helperText={errors.password?.message}
                autoComplete="current-password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
              />
              <Button
                className="login-btn"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {isLoading ? <CircularProgress className={classes.circle} size={35} /> : 'Sign In'}
              </Button>
            </form>
          </div>
        </Paper>
        {error && (
          <div className={classes.root}>
            <Alert severity="error" onClose={resetError}><AlertTitle>Error</AlertTitle>{error}</Alert>
          </div>
        )}
      </Container>
      <Footer />
    </>
  );
};

Login.propTypes = {
  signIn: PropTypes.func,
  resetError: PropTypes.func,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

Login.defaultProps = {
  signIn() { },
  resetError() { },
  error: '',
};

export default Login;
