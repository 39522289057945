import { handleActions } from 'redux-actions';

import {
  SET_INSTANCES,
  SET_INSTANCES_LOADING,
  SET_START_INSTANCE_LOADING,
  SET_RESTART_INSTANCE_LOADING,
  SET_STOP_INSTANCE_LOADING,
  SET_INSTANCE_LAUNCHES,
  SET_INSTANCE_LAUNCHES_LOADING,
  SET_INSTANCE_LAUNCHES_PAGINATION,
  SET_INSTANCE_LAUNCH_OBJECTS,
  SET_INSTANCE_LAUNCH_OBJECTS_LOADING,
} from '../actions/instances';

const INITIAL_STATE = {
  isLoading: false,
  isStartInstanceLoading: false,
  isRestartInstanceLoading: false,
  isStopInstanceLoading: false,
  isInstanceLaunchesLoading: false,
  isInstanceLaunchObjectsLoading: false,
  items: [],
  launches: [],
  launchesPagination: {
    page: 1,
    offset: 100,
    totalCount: 0,
  },
  launchObjects: [],
};

const ACTION_HANDLERS = {
  [SET_INSTANCES]: (state, { payload: items }) => ({
    ...state,
    items,
  }),
  [SET_INSTANCES_LOADING]: (state, { payload: { isLoading } }) => ({
    ...state,
    isLoading,
  }),
  [SET_START_INSTANCE_LOADING]: (state, { payload: { isStartInstanceLoading } }) => ({
    ...state,
    isStartInstanceLoading,
  }),
  [SET_RESTART_INSTANCE_LOADING]: (state, { payload: { isRestartInstanceLoading } }) => ({
    ...state,
    isRestartInstanceLoading,
  }),
  [SET_STOP_INSTANCE_LOADING]: (state, { payload: { isStopInstanceLoading } }) => ({
    ...state,
    isStopInstanceLoading,
  }),
  [SET_INSTANCE_LAUNCHES]: (state, { payload: launches }) => ({
    ...state,
    launches,
  }),
  [SET_INSTANCE_LAUNCHES_LOADING]: (state, { payload: { isInstanceLaunchesLoading } }) => ({
    ...state,
    isInstanceLaunchesLoading,
  }),
  [SET_INSTANCE_LAUNCHES_PAGINATION]: (state, { payload: launchesPagination }) => ({
    ...state,
    launchesPagination: {
      ...launchesPagination,
      totalCount: launchesPagination.total_count,
    },
  }),
  [SET_INSTANCE_LAUNCH_OBJECTS]: (state, { payload: launchObjects }) => ({
    ...state,
    launchObjects,
  }),
  [SET_INSTANCE_LAUNCH_OBJECTS_LOADING]: (state, { payload: { isInstanceLaunchObjectsLoading } }) => ({
    ...state,
    isInstanceLaunchObjectsLoading,
  }),
};

export default handleActions(ACTION_HANDLERS, INITIAL_STATE);
