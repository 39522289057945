import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Link } from '@material-ui/core';
import { deepOrange, red } from '@material-ui/core/colors';
import { getVersionObjectsPath, HtmlTooltip, useCommonStyles } from '../NeuralNetworks/NeuralNetworks';
import { getFormattedDate } from '../../utils/date';
import { setItemIds } from '../../utils/itemIds';
import { RESTART_INSTANCE_TASK_STATES } from '../../utils/tasksStateConstants';
import { getLaunchObjectsPath } from '../InstanceLaunches/InstanceLaunches';
import ROUTES from '../../layout/app/routes';

import StickyHeadTable from '../commons/StickyHeadTable';
import StyledButton from '../commons/StyledButton';

const OlrInstances = props => {
  const classes = useCommonStyles();
  const {
    olrInstances,
    tasksState,
    startInstance,
    restartInstance,
    stopInstance,
    isStartInstanceLoading,
    isRestartInstanceLoading,
    isStopInstanceLoading,
  } = props;
  const instanceTasks = tasksState.filter(task => task.type === 3);
  const restartTasksState = instanceTasks[instanceTasks.length - 1] || {};

  const columnProps = [
    {
      field: 'instance_name',
      label: 'Instance Name',
      minWidth: 170,
      align: 'center',
    },
    {
      field: 'instance_description',
      label: 'Description',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'server',
      label: 'Server',
      minWidth: 170,
      align: 'center',
    },
    {
      field: 'port',
      label: 'Port',
      minWidth: 170,
      align: 'center',
    },
    {
      field: 'last_start_time',
      label: 'Last Action',
      minWidth: 110,
      align: 'center',
      render: (value, item) => {
        const { last_start_time: lastStartTime, last_stop_time: lastStopTime } = item;
        const date = lastStartTime || lastStopTime || null;
        const action = date && (lastStartTime ? '(Started)' : lastStopTime ? '(Stopped)' : null);
        return date && action ? (
          <Link
            className={classes.link}
            component={RouterLink}
            to={getLaunchObjectsPath(item.instance_id, { launch_id: item.last_launch_id })}
          >
            {`${getFormattedDate(date)} ${action}`}
          </Link>
        ) : null;
      },
    },
    {
      field: 'last_nn_version_id',
      label: 'NN Version',
      minWidth: 110,
      align: 'center',
      render: value => (
        <Link
          className={classes.link}
          component={RouterLink}
          to={getVersionObjectsPath(value)}
        >
          {value}
        </Link>
      ),
    },
    {
      field: 'status',
      label: 'Status',
      minWidth: 110,
      align: 'center',
      render: () => (
        <HtmlTooltip title={RESTART_INSTANCE_TASK_STATES[restartTasksState.state]?.description ?? ''} placement="top">
          <div>{RESTART_INSTANCE_TASK_STATES[restartTasksState.state]?.status}</div>
        </HtmlTooltip>
      ),
    },
    {
      field: 'actions',
      label: 'Actions',
      minWidth: 110,
      align: 'center',
      render: (value, item) => (
        <div className={classes.actionButtons}>
          <Link
            className={classes.link}
            component={RouterLink}
            to={ROUTES.instanceLaunches.path.replace(':instanceId', item.instance_id).replace(':page', '1')}
          >
            View history
          </Link>
          {[1, 1000].includes(restartTasksState.state) && (
            <StyledButton
              title="Stop..."
              paletteColor={red}
              onClick={() => stopInstance({ instanceId: item.instance_id })}
              isInProgress={isStopInstanceLoading}
              disabled={isStartInstanceLoading || isRestartInstanceLoading}
            />
          )}
          {[1, 1000].includes(restartTasksState.state) && (
            <StyledButton
              title="Start..."
              paletteColor={red}
              onClick={() => startInstance(item)}
              isInProgress={isStartInstanceLoading}
              disabled={isStopInstanceLoading || isRestartInstanceLoading}
            />
          )}
          {(!restartTasksState.state || [1, 1000].includes(restartTasksState.state)) && (
            <StyledButton
              title="Restart..."
              paletteColor={deepOrange}
              onClick={() => restartInstance(item)}
              isInProgress={isRestartInstanceLoading}
              disabled={isStopInstanceLoading || isStartInstanceLoading}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <Container component="main" className={classes.root} maxWidth={false}>
      <StickyHeadTable
        columnProps={columnProps}
        items={setItemIds(olrInstances)}
      />
    </Container>
  );
};

OlrInstances.propTypes = {
  isStartInstanceLoading: PropTypes.bool,
  isRestartInstanceLoading: PropTypes.bool,
  isStopInstanceLoading: PropTypes.bool,
  olrInstances: PropTypes.array,
  tasksState: PropTypes.array,
  startInstance: PropTypes.func,
  restartInstance: PropTypes.func,
  stopInstance: PropTypes.func,
};

OlrInstances.defaultProps = {
  isStartInstanceLoading: false,
  isRestartInstanceLoading: false,
  isStopInstanceLoading: false,
  olrInstances: [],
  tasksState: [],
  startInstance() { },
  restartInstance() { },
  stopInstance() { },
};

export default OlrInstances;
