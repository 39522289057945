import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  getNeuralNetworksData,
  stopTraining,
  continueTraining,
  finishTraining,
  removeTraining,
  removeVersion,
} from '../../store/actions/versions';

import NeuralNetworksView from './NeuralNetworks';

const mapStateToProps = ({
  versions: {
    items: versions,
    itemsPagination,
    isStopTrainingLoading,
    isContinueTrainingLoading,
    isFinishTrainingLoading,
    isRemoveTrainingLoading,
    isRemoveVersionLoading,
  },
  tasksState: { items: tasksState },
}) => ({
  versions,
  versionsPagination: itemsPagination,
  isStopTrainingLoading,
  isContinueTrainingLoading,
  isFinishTrainingLoading,
  isRemoveTrainingLoading,
  isRemoveVersionLoading,
  tasksState,
});

const mapDispatchToProps = dispatch => ({
  getNeuralNetworksData: params => dispatch(getNeuralNetworksData(params)),
  stopTraining: () => dispatch(stopTraining()),
  continueTraining: () => dispatch(continueTraining()),
  finishTraining: payload => dispatch(finishTraining(payload)),
  removeTraining: () => dispatch(removeTraining()),
  removeVersion: versionId => dispatch(removeVersion(versionId)),
});

const NeuralNetworks = props => {
  const { page } = useParams();
  const location = useLocation();

  useEffect(() => {
    props.getNeuralNetworksData({ page, offset: location.state?.offset || 100 });
  }, [page, location.state]);

  return <NeuralNetworksView {...props} />;
};

NeuralNetworks.propTypes = {
  getNeuralNetworksData: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(NeuralNetworks);
