import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import StyledButton from '../../commons/StyledButton';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& .MuiDialogActions-root': {
      margin: '15px 0',
      '& > button': {
        minWidth: '100px',
        '&:first-child': {
          marginRight: '30px',
        },
      },
    },
  },
  titleDivider: {
    margin: '0 25px 10px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiFormLabel-root.Mui-focused': {
      color: '#90caf9',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#90caf9',
    },
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    margin: '10px 0 40px',
  },
}));

const EditAugmentationDialog = props => {
  const classes = useStyles();
  const { isOpen, visualObject, onClose } = props;

  const [augmentation, setAugmentation] = useState({ ...visualObject?.augmentation });

  useEffect(() => {
    const initAugmentation = {
      rotation: String(visualObject?.augmentation?.rotation),
      fliph: String(visualObject?.augmentation?.fliph),
      flipv: String(visualObject?.augmentation?.flipv),
      noise: String(visualObject?.augmentation?.noise),
      blur: String(visualObject?.augmentation?.blur),
    };
    setAugmentation(initAugmentation);
  }, [visualObject]);

  const { register, handleSubmit, reset } = useForm({});

  const handleChange = event => {
    setAugmentation({ ...augmentation, [event.target.name]: event.target.value });
  };

  const handleClose = () => {
    setAugmentation({});
    reset();
    onClose();
  };

  const onSubmit = () => {
    visualObject.augmentation.rotation = augmentation.rotation === 'true';
    visualObject.augmentation.fliph = augmentation.fliph === 'true';
    visualObject.augmentation.flipv = augmentation.flipv === 'true';
    visualObject.augmentation.noise = augmentation.noise === 'true';
    visualObject.augmentation.blur = augmentation.blur === 'true';
    onClose();
  };

  return (
    <div>
      <Dialog open={isOpen} maxWidth="md" fullWidth onClose={() => { }}>
        <DialogTitle>Edit Object Augmentation</DialogTitle>
        <Divider className={classes.titleDivider} />
        <DialogContent>
          <form className={classes.container} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.wrapper}>
              {['Rotation', 'FlipH', 'FlipV', 'Noise', 'Blur'].map(fieldName => (
                <FormControl key={fieldName} className={classes.formControl}>
                  <InputLabel htmlFor={fieldName.toLowerCase()}>{fieldName}</InputLabel>
                  <Select
                    {...register(fieldName.toLowerCase())}
                    native
                    value={augmentation[fieldName.toLowerCase()]}
                    onChange={handleChange}
                    input={<Input id={fieldName.toLowerCase()} />}
                  >
                    <option value="true">Enabled</option>
                    <option value="false">Disabled</option>
                  </Select>
                </FormControl>
              ))}
            </div>
            <Divider />
            <DialogActions>
              <StyledButton
                title="Cancel"
                paletteColor={red}
                onClick={handleClose}
                size="large"
              />
              <StyledButton
                type="submit"
                title="Save"
                paletteColor={green}
                size="large"
              />
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

EditAugmentationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  visualObject: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
};

EditAugmentationDialog.defaultProps = {
  visualObject: null,
};

export default EditAugmentationDialog;
