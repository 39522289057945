import React from 'react';

import './index.scss';

const NotFoundPage = () => (
  <div className="page-not-found">
    <h1>404</h1>
    <h2>Page not found</h2>
  </div>
);

export default NotFoundPage;
