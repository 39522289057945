import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import Loading from '../../commons/Loading';
import StyledButton from '../../commons/StyledButton';

import { setItemIds } from '../../../utils/itemIds';
import { addBase64ImageHeader } from '../../../utils/images';

const useStyles = makeStyles(() => ({
  imagesListWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 100%',
    minWidth: '640px',
    minHeight: '360px',
    maxWidth: '640px',
    maxHeight: '360px',
    overflowY: 'auto',
  },
  imageWrapper: {
    margin: '5px 6px 15px',
  },
  subTitle: {
    flex: '1 1 100%',
    margin: '0 5px',
    fontSize: 14,
    '& span': {
      color: '#c3c3c3',
    },
  },
  titleDivider: {
    margin: '0 25px 10px',
  },
  noImages: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: '20px',
    marginTop: '100px',
    color: '#c3c3c3',
  },
  actionsDivider: {
    margin: '10px 0',
  },
}));

const ExampleImagesDialog = props => {
  const classes = useStyles();
  const {
    isOpen,
    onClose,
    visualObject,
    exampleImages,
    isClientRequestExampleImagesLoading,
    getClientRequestExampleImages,
  } = props;
  const exampleImagesList = setItemIds(exampleImages.map(base64Image => ({ base64Image })));

  useEffect(() => {
    if (visualObject) {
      getClientRequestExampleImages(visualObject.request_id);
    }
  }, [visualObject]);

  const handleClose = () => {
    onClose();
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={() => { }} maxWidth="md" className={classes.dialogWrapper}>
        <DialogTitle>Client Request Example Images</DialogTitle>
        <Divider className={classes.titleDivider} />
        <DialogContent>
          {!!visualObject && (
            <>
              <Typography className={classes.subTitle} variant="h6" id="requestId" component="div">
                Client Request ID: <span>{visualObject.request_id}</span>
              </Typography>
              <Typography className={classes.subTitle} variant="h6" id="clientObjectName" component="div">
                Client Object Name: <span>{visualObject.client_object_name}</span>
              </Typography>
              <Typography className={classes.subTitle} variant="h6" id="objectSystemName" component="div">
                Object: <span>{visualObject.object_system_name}</span>
              </Typography>
            </>
          )}
          <div className={classes.imagesListWrapper}>
            {isClientRequestExampleImagesLoading ? (
              <Loading />
            ) : (
              <>
                {exampleImagesList.length > 0 ? (
                  <>
                    {exampleImagesList.map((exampleImage, index) => (
                      <Fragment key={exampleImage.id}>
                        <Typography
                          className={classes.subTitle}
                          variant="h6"
                          id={`exampleImage${index}`}
                          component="div"
                        >
                          Example Image {index + 1}:
                        </Typography>
                        <div className={classes.imageWrapper}>
                          <CardMedia
                            component="img"
                            image={addBase64ImageHeader(exampleImage.base64Image)}
                          />
                        </div>
                      </Fragment>
                    ))}
                  </>
                ) : (
                  <div className={classes.noImages}>No images</div>
                )}
              </>
            )}
          </div>
          <Divider className={classes.actionsDivider} />
          <DialogActions>
            <StyledButton
              title="Close"
              paletteColor={green}
              onClick={handleClose}
              size="large"
            />
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

ExampleImagesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isClientRequestExampleImagesLoading: PropTypes.bool.isRequired,
  getClientRequestExampleImages: PropTypes.func.isRequired,
  visualObject: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.oneOf([null]).isRequired,
  ]),
  exampleImages: PropTypes.array.isRequired,
};

ExampleImagesDialog.defaultProps = {
  visualObject: null,
};

export default ExampleImagesDialog;
