import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Checkbox } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { useCommonStyles } from '../NeuralNetworks/NeuralNetworks';
import ROUTES from '../../layout/app/routes';
import { getFormattedDate } from '../../utils/date';
import { setItemIds } from '../../utils/itemIds';

import StickyHeadTable from '../commons/StickyHeadTable';
import StyledButton from '../commons/StyledButton';
import ObjectWithLogo from '../commons/ObjectWithLogo';
import ButtonLink from '../commons/ButtonLink';
import EditAugmentationDialog from '../modals/EditAugmentationDialog';

const useStyles = makeStyles(() => ({
  augmentation: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 16,
  },
  styledButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const NewTraining = props => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const { visualObjects, visualObjectsPagination, startNewTraining, isLoading } = props;
  const newTrainingObjects = [];

  const [isOpenEditAugmentation, setIsOpenEditAugmentation] = useState(false);
  const [selectedVisualObject, setSelectedVisualObject] = useState(null);

  const handleOpenEditAugmentationDialog = visualObject => {
    if (visualObject) {
      setSelectedVisualObject(visualObject);
    }

    setIsOpenEditAugmentation(true);
  };

  const handleCloseEditAugmentationDialog = () => {
    setSelectedVisualObject(null);
    setIsOpenEditAugmentation(false);
  };

  const handleCheckbox = (isChecked, item) => {
    if (isChecked) {
      newTrainingObjects.push(item);
    } else {
      newTrainingObjects.splice(newTrainingObjects.indexOf(item), 1);
    }
  };

  const handleStartNewTraining = () => {
    if (!newTrainingObjects.length) {
      return;
    }

    const trainingObjects = newTrainingObjects.map(item => ({
      name: item.object_system_name,
      augmentation: item.augmentation,
    }));

    startNewTraining({ objects: trainingObjects });
  };

  const columnProps = [
    {
      field: 'object_system_name',
      label: 'Object',
      minWidth: 170,
      align: 'center',
      render: (value, item) => (
        <div style={{ display: 'flex' }}>
          <ObjectWithLogo
            icon={item.icon}
            objectSystemName={value}
          />
        </div>
      ),
    },
    {
      field: 'request_id',
      label: 'Request ID',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'creation_time',
      label: 'Added',
      minWidth: 170,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'last_update_time',
      label: 'Last Updated',
      minWidth: 170,
      align: 'center',
      render: value => getFormattedDate(value),
    },
    {
      field: 'im_train_true',
      label: 'Train True',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'im_train_false',
      label: 'Train False',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'im_valid_true',
      label: 'Valid True',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'im_valid_false',
      label: 'Valid False',
      minWidth: 110,
      align: 'center',
    },
    {
      field: 'augmentation',
      label: 'Augmentation',
      minWidth: 170,
      align: 'center',
      render: (value, item) => {
        const { rotation, fliph, flipv, noise, blur } = value;
        const getPlusMinus = val => (val ? '+' : '-');
        return (
          <div className={classes.augmentation}>
            <span>
              {[
                getPlusMinus(rotation),
                getPlusMinus(fliph),
                getPlusMinus(flipv),
                getPlusMinus(noise),
                getPlusMinus(blur),
              ].join('|')}
            </span>
            <ButtonLink title="Edit" onClick={() => handleOpenEditAugmentationDialog(item)} />
          </div>
        );
      },
    },
    {
      field: 'use',
      label: 'Use',
      minWidth: 100,
      align: 'center',
      render: (value, item) => (
        <Checkbox
          className={commonClasses.checkbox}
          color="primary"
          onChange={event => handleCheckbox(event.target.checked, item)}
        />
      ),
    },
  ];

  return (
    <Container component="main" className={commonClasses.root} maxWidth={false}>
      <StickyHeadTable
        columnProps={columnProps}
        items={setItemIds(visualObjects)}
        itemsPagination={{ ...visualObjectsPagination, pathname: ROUTES.newTraining.path }}
      />
      <div className={classes.styledButtonContainer}>
        <StyledButton
          title="Start"
          paletteColor={green}
          isInProgress={isLoading}
          onClick={handleStartNewTraining}
        />
      </div>
      <EditAugmentationDialog
        isOpen={isOpenEditAugmentation}
        visualObject={selectedVisualObject}
        onClose={handleCloseEditAugmentationDialog}
      />
    </Container>
  );
};

NewTraining.propTypes = {
  isLoading: PropTypes.bool,
  visualObjects: PropTypes.array,
  visualObjectsPagination: PropTypes.object,
  startNewTraining: PropTypes.func,
};

NewTraining.defaultProps = {
  isLoading: false,
  visualObjects: [],
  visualObjectsPagination: {},
  startNewTraining() { },
};

export default NewTraining;
