import { createAction } from 'redux-actions';
import { Authorized } from '../../api/authorized';
import { getTasksState, setTasksState } from './tasksState';
import { getUserToken, setError, setPageLoading, handleCommonError } from './session';

export const SET_VERSIONS = 'SET_VERSIONS';
export const SET_VERSIONS_LOADING = 'SET_VERSIONS_LOADING';
export const SET_VERSIONS_PAGINATION = 'SET_VERSIONS_PAGINATION';
export const SET_STOP_TRAINING_LOADING = 'SET_STOP_TRAINING_LOADING';
export const SET_CONTINUE_TRAINING_LOADING = 'SET_CONTINUE_TRAINING_LOADING';
export const SET_FINISH_TRAINING_LOADING = 'SET_FINISH_TRAINING_LOADING';
export const SET_REMOVE_TRAINING_LOADING = 'SET_REMOVE_TRAINING_LOADING';
export const SET_VERSION_OBJECTS = 'SET_VERSION_OBJECTS';
export const SET_VERSION_OBJECTS_LOADING = 'SET_VERSION_OBJECTS_LOADING';
export const SET_VERSION_ACTIVE_LAUNCHES = 'SET_VERSION_ACTIVE_LAUNCHES';
export const SET_VERSION_ACTIVE_LAUNCHES_LOADING = 'SET_VERSION_ACTIVE_LAUNCHES_LOADING';
export const SET_REMOVE_VERSION_LOADING = 'SET_REMOVE_VERSION_LOADING';

export const setVersions = createAction(SET_VERSIONS);
export const setVersionsLoading = createAction(SET_VERSIONS_LOADING, isLoading => ({ isLoading }));
export const setVersionsPagination = createAction(SET_VERSIONS_PAGINATION);
export const setStopTrainingLoading = createAction(
  SET_STOP_TRAINING_LOADING,
  isStopTrainingLoading => ({ isStopTrainingLoading }),
);
export const setContinueTrainingLoading = createAction(
  SET_CONTINUE_TRAINING_LOADING,
  isContinueTrainingLoading => ({ isContinueTrainingLoading }),
);
export const setFinishTrainingLoading = createAction(
  SET_FINISH_TRAINING_LOADING,
  isFinishTrainingLoading => ({ isFinishTrainingLoading }),
);
export const setRemoveTrainingLoading = createAction(
  SET_REMOVE_TRAINING_LOADING,
  isRemoveTrainingLoading => ({ isRemoveTrainingLoading }),
);
export const setVersionObjects = createAction(SET_VERSION_OBJECTS);
export const setVersionObjectsLoading = createAction(
  SET_VERSION_OBJECTS_LOADING,
  isVersionObjectsLoading => ({ isVersionObjectsLoading }),
);
export const setVersionActiveLaunches = createAction(SET_VERSION_ACTIVE_LAUNCHES);
export const setVersionActiveLaunchesLoading = createAction(
  SET_VERSION_ACTIVE_LAUNCHES_LOADING,
  isVersionActiveLaunchesLoading => ({ isVersionActiveLaunchesLoading }),
);
export const setRemoveVersionLoading = createAction(
  SET_REMOVE_VERSION_LOADING,
  isRemoveVersionLoading => ({ isRemoveVersionLoading }),
);

export const getNeuralNetworksData = params => (dispatch, getState) => {
  const { page, offset } = params;
  const { versions: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setVersionsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return Promise.allSettled([authorized.getTasksState(), authorized.getVersions({ page, offset })])
    .then(results => {
      const fulfilled = results.filter(result => result.status === 'fulfilled').map(result => result.value);
      const rejected = results.filter(result => result.status === 'rejected').map(result => result.reason);
      const errors = [];
      const collections = [
        { collectionName: 'tasks_state', action: setTasksState },
        { collectionName: 'versions', action: setVersions, paginatedAction: setVersionsPagination },
      ];

      rejected.forEach(({ response }) => {
        errors.push(response?.data?.error_message);
        handleCommonError({ response });
      });

      fulfilled.forEach(collectionData => {
        const {
          collectionName,
          action,
          paginatedAction,
        } = collections.find(item => !!collectionData[item.collectionName]);
        dispatch(action(collectionData[collectionName]));

        if (paginatedAction) {
          dispatch(paginatedAction(collectionData.pagination));
        }
      });

      if (errors.length > 0) {
        dispatch(setError({ error: errors.join(',') }));
      }
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setVersionsLoading(false));
    });
};

export const getVersions = params => (dispatch, getState) => {
  const { versions: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setVersionsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getVersions(params)
    .then(data => {
      dispatch(setVersions(data.versions));
      dispatch(setVersionsPagination(data.pagination));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setVersionsLoading(false));
    });
};

export const getVersionObjects = versionId => (dispatch, getState) => {
  const { versions: { isVersionObjectsLoading } } = getState();

  if (isVersionObjectsLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setVersionObjectsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getVersionObjects(versionId)
    .then(data => {
      dispatch(setVersionObjects(data.nn_objects));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setVersionObjectsLoading(false));
    });
};

export const getVersionActiveLaunches = versionId => (dispatch, getState) => {
  const { versions: { isVersionActiveLaunchesLoading } } = getState();

  if (isVersionActiveLaunchesLoading) {
    return;
  }

  dispatch(setVersionActiveLaunchesLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getVersionActiveLaunches(versionId)
    .then(data => {
      dispatch(setVersionActiveLaunches(data.nn_launches));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setVersionActiveLaunchesLoading(false));
    });
};

export const removeVersion = versionId => (dispatch, getState) => {
  const { versions: { isRemoveVersionLoading, items } } = getState();

  if (isRemoveVersionLoading) {
    return;
  }

  dispatch(setRemoveVersionLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.removeVersion(versionId)
    .then(() => {
      dispatch(setVersions(items.filter(item => item.version_id !== versionId)));
      dispatch(getTasksState());
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setRemoveVersionLoading(false));
    });
};

export const stopTraining = () => (dispatch, getState) => {
  const { versions: { isStopTrainingLoading } } = getState();

  if (isStopTrainingLoading) {
    return;
  }

  dispatch(setStopTrainingLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.stopTraining()
    .then(() => {
      dispatch(getTasksState());
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setStopTrainingLoading(false));
    });
};

export const continueTraining = () => (dispatch, getState) => {
  const { versions: { isContinueTrainingLoading } } = getState();

  if (isContinueTrainingLoading) {
    return;
  }

  dispatch(setContinueTrainingLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.continueTraining()
    .then(() => {
      dispatch(getTasksState());
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setContinueTrainingLoading(false));
    });
};

export const finishTraining = payload => (dispatch, getState) => {
  const { versions: { isFinishTrainingLoading } } = getState();

  if (isFinishTrainingLoading) {
    return;
  }

  dispatch(setFinishTrainingLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.finishTraining(payload)
    .then(() => {
      dispatch(getTasksState());
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setFinishTrainingLoading(false));
    });
};

export const removeTraining = () => (dispatch, getState) => {
  const { versions: { isRemoveTrainingLoading } } = getState();

  if (isRemoveTrainingLoading) {
    return;
  }

  dispatch(setRemoveTrainingLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.removeTraining()
    .then(() => {
      dispatch(getTasksState());
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setRemoveTrainingLoading(false));
    });
};
