const ROUTES = {
  neuralNetworks: {
    path: '/neural-networks/:page',
    name: 'Neural Networks',
  },
  clientRequests: {
    path: '/client-requests/:page',
    name: 'Client Requests',
  },
  visualObjects: {
    path: '/visual-objects/:page',
    name: 'Visual Objects',
  },
  olrInstances: {
    path: '/olr-instances',
    name: 'OLR Instances',
  },
  newTraining: {
    path: '/new-training/:page',
    name: 'New Training',
  },
  get instanceLaunches() {
    return {
      path: `${this.olrInstances.path}/:instanceId/launches/:page`,
      name: 'Instance Launches',
    };
  },
  get instanceLaunchObjects() {
    return {
      path: `${this.olrInstances.path}/:instanceId/launch/:launchId/objects/:launchMode`,
      name: 'Instance Launch Objects',
    };
  },
  get versionObjects() {
    return {
      path: `${this.neuralNetworks.path.replace('/:page', '')}/:versionId/objects`,
      name: 'Version Objects',
    };
  },
};

const getNavigationMenuItemWithPage = navigationMenuItem => ({
  ...navigationMenuItem,
  path: navigationMenuItem.path.replace(':page', '1'),
});

export const NAVIGATION_MENU = [
  getNavigationMenuItemWithPage(ROUTES.neuralNetworks),
  getNavigationMenuItemWithPage(ROUTES.clientRequests),
  getNavigationMenuItemWithPage(ROUTES.visualObjects),
  ROUTES.olrInstances,
  getNavigationMenuItemWithPage(ROUTES.newTraining),
];

export default ROUTES;
