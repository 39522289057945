import { createAction } from 'redux-actions';
import { Authorized } from '../../api/authorized';
import { getUserToken, setError, setPageLoading, handleCommonError } from './session';
import { ADDITIONAL_TASK_STATES } from '../../utils/tasksStateConstants';
import { wait } from '../../utils/async';

const { REACT_APP_CREATE_OBJECT_REQUEST_TIMEOUT, REACT_APP_CREATE_OBJECT_REQUEST_RETRIES } = process.env;

export const SET_OBJECTS = 'SET_OBJECTS';
export const SET_OBJECTS_LOADING = 'SET_OBJECTS_LOADING';
export const SET_OBJECTS_PAGINATION = 'SET_OBJECTS_PAGINATION';
export const SET_OBJECT_DEFAULTS_SETTINGS = 'SET_OBJECT_DEFAULTS_SETTINGS';
export const SET_OBJECT_DEFAULTS_SETTINGS_LOADING = 'SET_OBJECT_DEFAULTS_SETTINGS_LOADING';

export const setObjects = createAction(SET_OBJECTS);
export const setObjectsLoading = createAction(SET_OBJECTS_LOADING, isLoading => ({ isLoading }));
export const setObjectsPagination = createAction(SET_OBJECTS_PAGINATION);
export const setObjectDefaultsSettings = createAction(SET_OBJECT_DEFAULTS_SETTINGS);
export const setObjectDefaultsSettingsLoading = createAction(
  SET_OBJECT_DEFAULTS_SETTINGS_LOADING,
  isObjectDefaultsSettingsLoading => ({ isObjectDefaultsSettingsLoading }),
);

export const getObjects = params => (dispatch, getState) => {
  const { objects: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setObjectsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getObjects(params)
    .then(data => {
      dispatch(setObjects(data.objects));
      dispatch(setObjectsPagination(data.pagination));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setObjectsLoading(false));
    });
};

export const getObjectDefaultsSettings = () => (dispatch, getState) => {
  const { objects: { isObjectDefaultsSettingsLoading } } = getState();

  if (isObjectDefaultsSettingsLoading) {
    return;
  }

  dispatch(setObjectDefaultsSettingsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getObjectDefaultsSettings()
    .then(data => {
      dispatch(setObjectDefaultsSettings(data.defaults));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setObjectDefaultsSettingsLoading(false));
    });
};

const handleTasksStateErrors = (data, objectName) => {
  const errorPrefix = `Object: ${objectName}: `;
  const objectTasks = data.tasks_state.filter(task => task.type === 1);
  const lastObjectTaskState = objectTasks[objectTasks.length - 1] || {};

  if (lastObjectTaskState.error_code === 400) {
    throw new Error(`${errorPrefix}${lastObjectTaskState.error_message}`);
  } else if (lastObjectTaskState.state === 1001) {
    throw new Error(`${errorPrefix}${ADDITIONAL_TASK_STATES[lastObjectTaskState.state].description}`);
  }

  return { ...data, lastObjectTaskState };
};

const retryGetCreateObjectTasksState = (fn, objectName, delay, retries) => new Promise((resolve, reject) => fn()
  .then(data => handleTasksStateErrors(data, objectName))
  .then(data => {
    if (retries > 0 && data.lastObjectTaskState.state !== 1000) {
      return wait(delay)
        .then(retryGetCreateObjectTasksState.bind(null, fn, objectName, delay, retries - 1))
        .then(resolve)
        .catch(reject);
    }
    return resolve(data);
  })
  .catch(reject));

const refreshObjectsList = (params, dispatch) => {
  const authorized = new Authorized(getUserToken());

  return authorized.getObjects(params)
    .then(data => {
      dispatch(setObjects(data.objects));
    });
};

export const createObject = payload => (dispatch, getState) => {
  const { objects: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setObjectsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.createObject(payload)
    .then(() => retryGetCreateObjectTasksState(
      () => authorized.getTasksState(),
      payload.name,
      +REACT_APP_CREATE_OBJECT_REQUEST_TIMEOUT,
      +REACT_APP_CREATE_OBJECT_REQUEST_RETRIES,
    ))
    .then(() => refreshObjectsList({}, dispatch))
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setObjectsLoading(false));
    });
};

export const editObject = payload => (dispatch, getState) => {
  const { objects: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setObjectsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.editObject(payload)
    .then(() => retryGetCreateObjectTasksState(
      () => authorized.getTasksState(),
      payload.name,
      +REACT_APP_CREATE_OBJECT_REQUEST_TIMEOUT,
      +REACT_APP_CREATE_OBJECT_REQUEST_RETRIES,
    ))
    .then(() => refreshObjectsList({}, dispatch))
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setObjectsLoading(false));
    });
};

export const startNewTraining = payload => (dispatch, getState) => {
  const { objects: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setObjectsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.startNewTraining(payload)
    .then(data => data)
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setObjectsLoading(false));
    });
};
