import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    color: '#90caf9',
    textTransform: 'capitalize',
    textDecoration: 'underline',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      opacity: 0.7,
    },
  },
}));

const ButtonLink = props => {
  const classes = useStyles();
  const { title } = props;

  return (
    <Button
      className={classes.root}
      color="primary"
      disableRipple
      {...props}
    >
      {title}
    </Button>
  );
};

ButtonLink.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ButtonLink;
