import { handleActions } from 'redux-actions';

import {
  SET_OBJECTS,
  SET_OBJECTS_LOADING,
  SET_OBJECTS_PAGINATION,
  SET_OBJECT_DEFAULTS_SETTINGS,
  SET_OBJECT_DEFAULTS_SETTINGS_LOADING,
} from '../actions/objects';

const INITIAL_STATE = {
  isLoading: false,
  isObjectDefaultsSettingsLoading: false,
  items: [],
  itemsPagination: {
    page: 1,
    offset: 100,
    totalCount: 0,
  },
  objectDefaultsSettings: {},
};

const ACTION_HANDLERS = {
  [SET_OBJECTS]: (state, { payload: items }) => ({
    ...state,
    items,
  }),
  [SET_OBJECTS_LOADING]: (state, { payload: { isLoading } }) => ({
    ...state,
    isLoading,
  }),
  [SET_OBJECTS_PAGINATION]: (state, { payload: itemsPagination }) => ({
    ...state,
    itemsPagination: {
      ...itemsPagination,
      totalCount: itemsPagination.total_count,
    },
  }),
  [SET_OBJECT_DEFAULTS_SETTINGS]: (state, { payload: objectDefaultsSettings }) => ({
    ...state,
    objectDefaultsSettings,
  }),
  [SET_OBJECT_DEFAULTS_SETTINGS_LOADING]: (state, { payload: { isObjectDefaultsSettingsLoading } }) => ({
    ...state,
    isObjectDefaultsSettingsLoading,
  }),
};

export default handleActions(ACTION_HANDLERS, INITIAL_STATE);
