import { combineReducers } from 'redux';
import session from './session';
import requests from './requests';
import objects from './objects';
import versions from './versions';
import instances from './instances';
import tasksState from './tasksState';

const rootReducer = combineReducers({
  session,
  requests,
  objects,
  versions,
  instances,
  tasksState,
});

export default rootReducer;
