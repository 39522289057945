import { createAction } from 'redux-actions';
import { Authorized } from '../../api/authorized';
import { getUserToken, setError, setPageLoading, handleCommonError } from './session';

export const SET_CLIENT_REQUESTS = 'SET_CLIENT_REQUESTS';
export const SET_CLIENT_REQUESTS_LOADING = 'SET_CLIENT_REQUESTS_LOADING';
export const SET_CLIENT_REQUESTS_PAGINATION = 'SET_CLIENT_REQUESTS_PAGINATION';
export const SET_CLIENT_REQUEST_EXAMPLE_IMAGES = 'SET_CLIENT_REQUEST_EXAMPLE_IMAGES';
export const SET_CLIENT_REQUEST_EXAMPLE_IMAGES_LOADING = 'SET_CLIENT_REQUEST_EXAMPLE_IMAGES_LOADING';

export const setClientRequests = createAction(SET_CLIENT_REQUESTS);
export const setClientRequestsLoading = createAction(SET_CLIENT_REQUESTS_LOADING, isLoading => ({ isLoading }));
export const setClientRequestsPagination = createAction(SET_CLIENT_REQUESTS_PAGINATION);
export const setClientRequestExampleImages = createAction(SET_CLIENT_REQUEST_EXAMPLE_IMAGES);
export const setClientRequestExampleImagesLoading = createAction(
  SET_CLIENT_REQUEST_EXAMPLE_IMAGES_LOADING,
  isClientRequestExampleImagesLoading => ({ isClientRequestExampleImagesLoading }),
);

export const getClientRequests = params => (dispatch, getState) => {
  const { requests: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setClientRequestsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getClientRequests(params)
    .then(data => {
      dispatch(setClientRequests(data.requests));
      dispatch(setClientRequestsPagination(data.pagination));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setClientRequestsLoading(false));
    });
};

export const getClientRequestExampleImages = requestId => (dispatch, getState) => {
  const { requests: { isClientRequestExampleImagesLoading } } = getState();

  if (isClientRequestExampleImagesLoading) {
    return;
  }

  dispatch(setClientRequestExampleImagesLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getClientRequestExampleImages(requestId)
    .then(data => {
      dispatch(setClientRequestExampleImages(data.examples));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setClientRequestExampleImagesLoading(false));
    });
};
