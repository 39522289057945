import { connect } from 'react-redux';
import { createObject, editObject, getObjects } from '../../../store/actions/objects';

import CreateEditVisualObjectDialog from './CreateEditVisualObjectDialog';

const mapStateToProps = ({ tasksState, objects: { isLoading } }) => ({
  tasksState,
  isLoading,
});

const mapDispatchToProps = dispatch => ({
  createObject: payload => dispatch(createObject(payload)),
  editObject: payload => dispatch(editObject(payload)),
  getObjects: params => dispatch(getObjects(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEditVisualObjectDialog);
