import { handleActions } from 'redux-actions';

import {
  SET_AUTHENTICATED,
  SET_SESSION_LOADING,
  SET_PAGE_LOADING,
  SET_ERROR,
} from '../actions/session';

const INITIAL_STATE = {
  isAuthenticated: false,
  isLoading: false,
  isPageLoading: false,
  error: '',
};

const ACTION_HANDLERS = {
  [SET_AUTHENTICATED]: (state, { payload: { isAuthenticated } }) => ({
    ...state,
    isAuthenticated,
  }),
  [SET_SESSION_LOADING]: (state, { payload: { isLoading } }) => ({
    ...state,
    isLoading,
  }),
  [SET_PAGE_LOADING]: (state, { payload: { isPageLoading } }) => ({
    ...state,
    isPageLoading,
  }),
  [SET_ERROR]: (state, { payload: { error } }) => ({
    ...state,
    error,
  }),
};

export default handleActions(ACTION_HANDLERS, INITIAL_STATE);
