import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { getClientRequests } from '../../store/actions/requests';

import ClientRequestsView from './ClientRequests';

const mapStateToProps = ({ requests: { isLoading, items, itemsPagination } }) => ({
  isLoading,
  clientRequests: items,
  clientRequestsPagination: itemsPagination,
});

const mapDispatchToProps = dispatch => ({
  getClientRequests: params => dispatch(getClientRequests(params)),
});

const ClientRequests = props => {
  const { page } = useParams();
  const location = useLocation();

  useEffect(() => {
    props.getClientRequests({ page, offset: location.state?.offset || 100 });
  }, [page, location.state]);

  return <ClientRequestsView {...props} />;
};

ClientRequests.propTypes = {
  getClientRequests: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientRequests);
