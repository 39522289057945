import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '20px 0',
  },
  container: {
    maxHeight: '65vh',
  },
  tableTitle: {
    backgroundColor: '#024d8e',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    '& div': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
      height: 50,
      margin: '0 20px',
    },
  },
});

const StickyHeadTable = ({ columnProps, items, itemsPagination, hasPagination, tableTitle }) => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(itemsPagination?.page ? itemsPagination.page - 1 : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(itemsPagination?.offset || 10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);

    if (itemsPagination?.pathname) {
      history.push(itemsPagination.pathname.replace(':page', newPage + 1), { offset: rowsPerPage });
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);

    if (itemsPagination?.pathname) {
      history.push(itemsPagination.pathname.replace(':page', 1), { offset: +event.target.value });
    }
  };

  return (
    <Paper className={classes.root}>
      {!!tableTitle && (
        <div className={classes.tableTitle}>
          <Typography variant="h6" id="launchPeriod" component="div">{tableTitle}</Typography>
        </div>
      )}
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnProps.map(column => (
                <TableCell
                  key={column.field}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: column.backgroundColor || '#121212' }}
                >
                  {column.renderHeadCell ? column.renderHeadCell() : column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(
              itemsPagination?.totalCount ? items : items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            ).map(item => (
              <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
                {columnProps.map(column => {
                  const value = item[column.field];
                  return (
                    <TableCell
                      key={column.field}
                      align={column.align}
                      style={{
                        ...(column.bodyCellBgColor ? { backgroundColor: column.bodyCellBgColor(value, item) } : {}),
                      }}
                    >
                      {column.render ? column.render(value, item) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {hasPagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 1000]}
          component="div"
          count={itemsPagination?.totalCount || items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

StickyHeadTable.propTypes = {
  columnProps: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  itemsPagination: PropTypes.object,
  hasPagination: PropTypes.bool,
  tableTitle: PropTypes.string,
};

StickyHeadTable.defaultProps = {
  itemsPagination: {},
  hasPagination: true,
  tableTitle: '',
};

export default StickyHeadTable;
