export const wait = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Example of starting retryOperation:
 * retryOperation(func, 1000, 5)
 *   .then(console.log)
 *   .catch(console.log);
 */
export const retryOperation = (fn, delay, retries) => new Promise((resolve, reject) => fn()
  .then(resolve)
  .catch(error => {
    if (retries > 0) {
      return wait(delay)
        .then(retryOperation.bind(null, fn, delay, retries - 1))
        .then(resolve)
        .catch(reject);
    }
    return reject(error);
  }));
