import { connect } from 'react-redux';
import { setError } from '../../../store/actions/session';

import CommonErrorDialog from './CommonErrorDialog';

const mapStateToProps = ({ session: { error } }) => ({
  error,
});

const mapDispatchToProps = dispatch => ({
  resetError: () => dispatch(setError('')),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommonErrorDialog);
