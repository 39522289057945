import { createAction } from 'redux-actions';
import { Authorized } from '../../api/authorized';
import { getUserToken, setError, setPageLoading, handleCommonError } from './session';
import { getTasksState, setTasksState } from './tasksState';
import { setVersions } from './versions';

export const SET_INSTANCES = 'SET_INSTANCES';
export const SET_INSTANCES_LOADING = 'SET_INSTANCES_LOADING';
export const SET_START_INSTANCE_LOADING = 'SET_START_INSTANCE_LOADING';
export const SET_RESTART_INSTANCE_LOADING = 'SET_RESTART_INSTANCE_LOADING';
export const SET_STOP_INSTANCE_LOADING = 'SET_STOP_INSTANCE_LOADING';
export const SET_INSTANCE_LAUNCHES = 'SET_INSTANCE_LAUNCHES';
export const SET_INSTANCE_LAUNCHES_LOADING = 'SET_INSTANCE_LAUNCHES_LOADING';
export const SET_INSTANCE_LAUNCHES_PAGINATION = 'SET_INSTANCE_LAUNCHES_PAGINATION';
export const SET_INSTANCE_LAUNCH_OBJECTS = 'SET_INSTANCE_LAUNCH_OBJECTS';
export const SET_INSTANCE_LAUNCH_OBJECTS_LOADING = 'SET_INSTANCE_LAUNCH_OBJECTS_LOADING';

export const setInstances = createAction(SET_INSTANCES);
export const setInstancesLoading = createAction(SET_INSTANCES_LOADING, isLoading => ({ isLoading }));
export const setStartInstanceLoading = createAction(
  SET_START_INSTANCE_LOADING,
  isStartInstanceLoading => ({ isStartInstanceLoading }),
);
export const setRestartInstanceLoading = createAction(
  SET_RESTART_INSTANCE_LOADING,
  isRestartInstanceLoading => ({ isRestartInstanceLoading }),
);
export const setStopInstanceLoading = createAction(
  SET_STOP_INSTANCE_LOADING,
  isStopInstanceLoading => ({ isStopInstanceLoading }),
);
export const setInstanceLaunches = createAction(SET_INSTANCE_LAUNCHES);
export const setInstanceLaunchesPagination = createAction(SET_INSTANCE_LAUNCHES_PAGINATION);
export const setInstanceLaunchesLoading = createAction(
  SET_INSTANCE_LAUNCHES_LOADING,
  isInstanceLaunchesLoading => ({ isInstanceLaunchesLoading }),
);
export const setInstanceLaunchObjects = createAction(SET_INSTANCE_LAUNCH_OBJECTS);
export const setInstanceLaunchObjectsLoading = createAction(
  SET_INSTANCE_LAUNCH_OBJECTS_LOADING,
  isInstanceLaunchObjectsLoading => ({ isInstanceLaunchObjectsLoading }),
);

export const getInstancesData = () => (dispatch, getState) => {
  const { instances: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setInstancesLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return Promise.allSettled([authorized.getTasksState(), authorized.getInstances()])
    .then(results => {
      const fulfilled = results.filter(result => result.status === 'fulfilled').map(result => result.value);
      const rejected = results.filter(result => result.status === 'rejected').map(result => result.reason);
      const errors = [];
      const collections = [
        { collectionName: 'tasks_state', action: setTasksState },
        { collectionName: 'instances', action: setInstances },
      ];

      rejected.forEach(({ response }) => {
        errors.push(response?.data?.error_message);
        handleCommonError({ response });
      });

      fulfilled.forEach(collectionData => {
        const { collectionName, action } = collections.find(item => !!collectionData[item.collectionName]);
        dispatch(action(collectionData[collectionName]));
      });

      if (errors.length > 0) {
        dispatch(setError({ error: errors.join(',') }));
      }
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setInstancesLoading(false));
    });
};

export const getInstances = () => (dispatch, getState) => {
  const { instances: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setInstancesLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getInstances()
    .then(data => {
      dispatch(setInstances(data.instances));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setInstancesLoading(false));
    });
};

const getExtendedInstanceLaunches = (instanceLaunches, versions) => (
  instanceLaunches.map(item => {
    const itemVersion = versions.find(({ version_id: versionId }) => versionId === item.nn_version_id);
    return ({
      ...item,
      train_finished: itemVersion?.train_finished,
    });
  })
);

export const getInstanceLaunches = params => (dispatch, getState) => {
  const { instanceId, page, offset } = params;
  const { instances: { isInstanceLaunchesLoading, items } } = getState();

  if (isInstanceLaunchesLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setInstanceLaunchesLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return Promise.all([
    authorized.getInstanceLaunches({ instanceId, page, offset }),
    authorized.getVersions({ page, offset }),
    ...(!items.length ? [authorized.getInstances()] : []),
  ])
    .then(([launchesData, versionsData, instancesData]) => {
      dispatch(setInstanceLaunches(getExtendedInstanceLaunches(launchesData.launches, versionsData.versions)));
      dispatch(setInstanceLaunchesPagination(launchesData.pagination));
      dispatch(setVersions(versionsData.versions));

      if (instancesData) {
        dispatch(setInstances(instancesData.instances));
      }
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setInstanceLaunchesLoading(false));
    });
};

export const getInstanceLaunchObjects = ({ instanceId, launchId, page, offset }) => (dispatch, getState) => {
  const { instances: { isInstanceLaunchObjectsLoading, items } } = getState();

  if (isInstanceLaunchObjectsLoading) {
    return;
  }

  dispatch(setPageLoading(true));
  dispatch(setInstanceLaunchObjectsLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return Promise.all([
    authorized.getLaunchObjects(launchId),
    authorized.getInstanceLaunches({ instanceId, page, offset }),
    authorized.getVersions({ page, offset }),
    ...(!items.length ? [authorized.getInstances()] : []),
  ])
    .then(([launchObjectsData, launchesData, versionsData, instancesData]) => {
      dispatch(setInstanceLaunchObjects(launchObjectsData.launch_objects));
      dispatch(setInstanceLaunches(getExtendedInstanceLaunches(launchesData.launches, versionsData.versions)));
      dispatch(setInstanceLaunchesPagination(launchesData.pagination));
      dispatch(setVersions(versionsData.versions));

      if (instancesData) {
        dispatch(setInstances(instancesData.instances));
      }
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setPageLoading(false));
      dispatch(setInstanceLaunchObjectsLoading(false));
    });
};

export const startInstance = payload => (dispatch, getState) => {
  const { instances: { isStartInstanceLoading } } = getState();

  if (isStartInstanceLoading) {
    return;
  }

  dispatch(setStartInstanceLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getLaunchObjects(payload.last_launch_id)
    .then(data => authorized.startInstance({
      instanceId: payload.instance_id,
      payload: {
        nn_version_id: payload.last_nn_version_id,
        launch_objects: data.launch_objects.map(launchObject => ({
          name: launchObject.object_system_name,
          confidence_threshold: launchObject.confidence_threshold,
          enabled: launchObject.enabled,
        })),
      },
    }))
    .then(() => {
      dispatch(getTasksState());
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setStartInstanceLoading(false));
    });
};

export const restartInstance = payload => (dispatch, getState) => {
  const { instances: { isRestartInstanceLoading } } = getState();

  if (isRestartInstanceLoading) {
    return;
  }

  dispatch(setRestartInstanceLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return (payload.launch_objects
    ? Promise.resolve({ launch_objects: payload.launch_objects })
    : authorized.getLaunchObjects(payload.last_launch_id))
    .then(data => authorized.restartInstance({
      instanceId: payload.instance_id,
      payload: {
        nn_version_id: payload.last_nn_version_id,
        launch_objects: data.launch_objects.map(launchObject => ({
          name: launchObject.object_system_name,
          confidence_threshold: launchObject.confidence_threshold,
          enabled: launchObject.enabled,
        })),
      },
    }))
    .then(() => {
      dispatch(getTasksState());
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      if (payload.goToInstanceLaunches) {
        payload.goToInstanceLaunches();
      }

      dispatch(setRestartInstanceLoading(false));
    });
};

export const stopInstance = payload => (dispatch, getState) => {
  const { instances: { isStopInstanceLoading } } = getState();

  if (isStopInstanceLoading) {
    return;
  }

  dispatch(setStopInstanceLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.stopInstance(payload)
    .then(() => {
      dispatch(getTasksState());
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setStopInstanceLoading(false));
    });
};
