import axios from 'axios';
import { Base } from './base';

const DEFAULT_CURRENT_PAGE = 1;
const DEFAULT_PAGE_OFFSET = 100;

export class Authorized extends Base {
  async getObjects({ page = DEFAULT_CURRENT_PAGE, offset = DEFAULT_PAGE_OFFSET }) {
    return (await axios.get(`${this.baseUrl}/objects/${page}/${offset}`, this.getConfig()))?.data;
  }

  async getObjectDefaultsSettings() {
    return (await axios.get(`${this.baseUrl}/object/defaults`, this.getConfig()))?.data;
  }

  async getInstances() {
    return (await axios.get(`${this.baseUrl}/instances`, this.getConfig()))?.data;
  }

  async getClientRequests({ page = DEFAULT_CURRENT_PAGE, offset = DEFAULT_PAGE_OFFSET }) {
    return (await axios.get(`${this.baseUrl}/requests/${page}/${offset}`, this.getConfig()))?.data;
  }

  async getClientRequestExampleImages(requestId) {
    return (await axios.get(`${this.baseUrl}/request/${requestId}/examples`, this.getConfig()))?.data;
  }

  async getVersions({ page = DEFAULT_CURRENT_PAGE, offset = DEFAULT_PAGE_OFFSET }) {
    return (await axios.get(`${this.baseUrl}/nn/versions/${page}/${offset}`, this.getConfig()))?.data;
  }

  async getVersionObjects(versionId) {
    return (await axios.get(`${this.baseUrl}/nn/version/${versionId}/objects`, this.getConfig()))?.data;
  }

  async getVersionActiveLaunches(versionId) {
    return (await axios.get(`${this.baseUrl}/nn/version/${versionId}/active-launches`, this.getConfig()))?.data;
  }

  async removeVersion(versionId) {
    return (await axios.delete(`${this.baseUrl}/nn/version/${versionId}`, this.getConfig()))?.data;
  }

  async getTasksState() {
    return (await axios.get(`${this.baseUrl}/tasks/state`, this.getConfig()))?.data;
  }

  async getInstanceLaunches({ instanceId, page = DEFAULT_CURRENT_PAGE, offset = DEFAULT_PAGE_OFFSET }) {
    return (
      await axios.get(`${this.baseUrl}/launches/instance/${instanceId}/${page}/${offset}`, this.getConfig())
    )?.data;
  }

  async getLaunchObjects(launchId) {
    return (await axios.get(`${this.baseUrl}/launch/${launchId}/objects`, this.getConfig()))?.data;
  }

  async createObject(payload) {
    return (await axios.post(`${this.baseUrl}/object/create`, payload, this.getConfig()))?.data;
  }

  async editObject(payload) {
    return (await axios.post(`${this.baseUrl}/object/edit`, payload, this.getConfig()))?.data;
  }

  async startNewTraining(payload) {
    return (await axios.post(`${this.baseUrl}/train/start`, payload, this.getConfig()))?.data;
  }

  async stopTraining() {
    return (await axios.post(`${this.baseUrl}/train/stop`, {}, this.getConfig()))?.data;
  }

  async continueTraining() {
    return (await axios.post(`${this.baseUrl}/train/continue`, {}, this.getConfig()))?.data;
  }

  async finishTraining(payload) {
    return (await axios.post(`${this.baseUrl}/train/finish`, payload, this.getConfig()))?.data;
  }

  async removeTraining() {
    return (await axios.post(`${this.baseUrl}/train/remove`, {}, this.getConfig()))?.data;
  }

  async startInstance({ instanceId, payload }) {
    return (await axios.post(`${this.baseUrl}/instance/${instanceId}/start`, payload, this.getConfig()))?.data;
  }

  async restartInstance({ instanceId, payload }) {
    return (await axios.post(`${this.baseUrl}/instance/${instanceId}/restart`, payload, this.getConfig()))?.data;
  }

  async stopInstance({ instanceId }) {
    return (await axios.post(`${this.baseUrl}/instance/${instanceId}/stop`, {}, this.getConfig()))?.data;
  }
}
