import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import AUTH_ROUTES_LIST from './authRoutesList';

import Header from '../header';
import Loading from '../../components/commons/Loading';
import CommonErrorDialog from '../../components/modals/CommonErrorDialog';

const Layout = props => {
  const { isPageLoading } = props;

  return (
    <>
      <Header />
      {isPageLoading ? (
        <Loading />
      ) : (
        <Switch>
          {AUTH_ROUTES_LIST.map(({ path, component, exact }) => (
            <Route key={path} exact={exact} path={path} component={component} />
          ))}
        </Switch>
      )}
      <CommonErrorDialog />
    </>
  );
};

Layout.propTypes = {
  isPageLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ session: { isAuthenticated, isPageLoading } }) => ({
  isAuthenticated,
  isPageLoading,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
