import { createAction } from 'redux-actions';
import { Authorized } from '../../api/authorized';
import { getUserToken, setError, handleCommonError } from './session';

export const SET_TASKS_STATE = 'SET_TASKS_STATE';
export const SET_TASKS_STATE_LOADING = 'SET_TASKS_STATE_LOADING';

export const setTasksState = createAction(SET_TASKS_STATE);
export const setTasksStateLoading = createAction(SET_TASKS_STATE_LOADING, isLoading => ({ isLoading }));

export const getTasksState = () => (dispatch, getState) => {
  const { tasksState: { isLoading } } = getState();

  if (isLoading) {
    return;
  }

  dispatch(setTasksStateLoading(true));
  dispatch(setError(''));
  const authorized = new Authorized(getUserToken());

  return authorized.getTasksState()
    .then(data => {
      dispatch(setTasksState(data.tasks_state));
    })
    .catch(error => {
      dispatch(setError({ error: error.response?.data?.error_message || error.message || error }));
      handleCommonError(error);
    })
    .finally(() => {
      dispatch(setTasksStateLoading(false));
    });
};
