import { handleActions } from 'redux-actions';

import {
  SET_CLIENT_REQUESTS,
  SET_CLIENT_REQUESTS_LOADING,
  SET_CLIENT_REQUESTS_PAGINATION,
  SET_CLIENT_REQUEST_EXAMPLE_IMAGES,
  SET_CLIENT_REQUEST_EXAMPLE_IMAGES_LOADING,
} from '../actions/requests';

const INITIAL_STATE = {
  isLoading: false,
  isClientRequestExampleImagesLoading: false,
  exampleImages: [],
  items: [],
  itemsPagination: {
    page: 1,
    offset: 100,
    totalCount: 0,
  },
};

const ACTION_HANDLERS = {
  [SET_CLIENT_REQUESTS]: (state, { payload: items }) => ({
    ...state,
    items,
  }),
  [SET_CLIENT_REQUESTS_LOADING]: (state, { payload: { isLoading } }) => ({
    ...state,
    isLoading,
  }),
  [SET_CLIENT_REQUESTS_PAGINATION]: (state, { payload: itemsPagination }) => ({
    ...state,
    itemsPagination: {
      ...itemsPagination,
      totalCount: itemsPagination.total_count,
    },
  }),
  [SET_CLIENT_REQUEST_EXAMPLE_IMAGES]: (state, { payload: exampleImages }) => ({
    ...state,
    exampleImages,
  }),
  [SET_CLIENT_REQUEST_EXAMPLE_IMAGES_LOADING]: (state, { payload: { isClientRequestExampleImagesLoading } }) => ({
    ...state,
    isClientRequestExampleImagesLoading,
  }),
};

export default handleActions(ACTION_HANDLERS, INITIAL_STATE);
