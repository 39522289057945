import React from 'react';
import { connect } from 'react-redux';
import { signIn, setError } from '../../store/actions/session';

import LoginView from './Login';

const mapStateToProps = ({ session: { isLoading, error } }) => ({
  isLoading,
  error,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  signIn: ({ email, password }) => dispatch(signIn({ email, password }, ownProps.history)),
  resetError: () => dispatch(setError('')),
});

const Login = props => <LoginView {...props} />;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
